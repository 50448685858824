import React, { useState, useEffect } from 'react';
import { Typography, Card, Row, Col, Slider, Switch, Transfer, message } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { sendFeatureStatusToServer2,fetchSettingsFromServer } from './utils';



const { Title, Text } = Typography;
 

// Initialize an array of 50+ languages
const allLanguages = [
  "Afrikaans (South Africa)",
  "Albanian (Albania)",
  "Amharic (Ethiopia)",
  "Arabic (Saudi Arabia)",
  "Armenian (Armenia)",
  "Assamese (India)",
  "Azerbaijani (Azerbaijan)",
  "Basque (Spain)",
  "Belarusian",
  "Bengali (India)",
  "Bhojpuri",
  "Bosnian (Bosnia and Herzegovina)",
  "Breton",
  "Bulgarian (Bulgaria)",
  "Burmese (Myanmar)",
  "Catalan (Spain)",
  "Cebuano (Philipines)",
  "Chinese (Simplified)",
  "Chinese (Traditional)",
  "Croatian (Croatia)",
  "Czech (Czech Republic)",
  "Danish (Denmark)",
  "Dogri",
  "Dutch (Netherlands)",
  "English",

  "Estonian (Estonia)",
  "Faroese",
  "Filipino",
  "Filipino (Tagalog)",
  "Finnish (Finland)",
  "French",
  "Frisian",
  "Galician (Spain)",
  "Georgian (Georgia)",
  "German (Austria)",
  "German (Germany)",
  "Greek (Greece)",
  "Greenlandic",
  "Guarani",
  "Gujarati (India)",
  "Hausa (Nigeria)",
  "Hebrew (Israel)",
  "Hindi (India)",
  "Hungarian (Hungary)",
  "Icelandic (Iceland)",
  "Igbo (Nigeria)",
  "Indonesian (Indonesia)",
  "Irish (Ireland)",
  "Italian (Italy)",
  "Japanese (Japan)",
  "Kannada (India)",
  "Kashmiri",
  "Kazakh (Kazakhstan)",
  "Khmer (Cambodia)",
  "Kinyarwanda (Rwanda)",
  "Kongo (Congo)",
  "Konkani",
  "Korean (South Korea)",
  "Kurdish (Turkey)",
  "Kyrgyz (Kyrgyzstan)",
  "Lao (Laos)",
  "Latvian (Latvia)",
  "Lingala (Congo)",
  "Lithuanian (Lithuania)",
  "Malay (Malaysia)",
  "Malayalam (India)",
  "Maltese (Malta)",
  "Manipuri",
  "Maithili",
  "Mongolian (Mongolia)",
  "Nepali (India)",
  "Nepali (Nepal)",
  "Norwegian (Norway)",
  "Oromo (Ethiopia)",
  "Pashto (Afghanistan)",
  "Persian (Iran)",
  "Polish (Poland)",
  "Portuguese (Brazil)",
  "Portuguese (Portugal)",
  "Punjabi (India)",
  "Punjabi (Pakistan)",
  "Romanian (Romania)",
  "Russian (Russia)",
  "Sanskrit",
  "Scottish Gaelic",
  "Serbian (Serbia)",
  "Shona (Zimbabwe)",
  "Sindhi (India)",
  "Sindhi (Pakistan)",
  "Sinhala (Sri Lanka)",
  "Slovak (Slovakia)",
  "Slovenian (Slovenia)",
  "Somali (Somalia)",
  "Southern Sotho (South Africa)",
  "Spanish (Mexico)",
  "Spanish (Spain)",
  "Swahili (Kenya)",
  "Swati (South Africa)",
  "Swedish (Sweden)",
  "Tajik (Tajikistan)",
  "Tamil (India)",
  "Tatar",
  "Telugu (India)",
  "Thai (Thailand)",
  "Tigrinya (Ethiopia)",
  "Tsonga (South Africa)",
  "Turkish (Turkey)",
  "Turkmen (Turkmenistan)",
  "Tswana (Botswana)",
  "Tswana (South Africa)",
  "Ukrainian (Ukraine)",
  "Urdu (India)",
  "Urdu (Pakistan)",
  "Uyghur",
  "Uzbek (Uzbekistan)",
  "Vietnamese (Vietnam)",
  "Volapük",
  "Walloon",
  "Welsh (United Kingdom)",
  "Xhosa (South Africa)",
  "Yiddish",
  "Yoruba (Nigeria)",
  "Zulu (South Africa)",
  "Taiwanese Hokkien",
  "Taiwanese Hakka"
  ].map(lang => ({ key: lang, title: lang }));

  const featureNameToDBField = {
    "AiBasicTranslationEnabled": "AiBasicTranslationEnabled",
    "BasicTranslationFontAdjustmentEnabled": "BasicTranslationFontAdjustmentEnabled",
    "AdvancedTranslationEnabled": "AdvancedTranslationEnabled",
    "AdvancedTranslationCreativityLevel": "AdvancedTranslationCreativityLevel",
    "AdvancedTranslationVariantCount": "AdvancedTranslationVariantCount",
    "AdvancedTranslationConfidenceLevel": "AdvancedTranslationConfidenceLevel",
    "AdvancedTranslationBrandVoicePreservation": "AdvancedTranslationBrandVoicePreservation",
  };

  const sliderStyle = (isActive) => ({
    filter: isActive ? 'none' : 'grayscale(100%)',
    opacity: isActive ? 1 : 0.5,
  });





  function TranslationSettingsPage(props) {
    // Define default settings
    const defaultSettings = {
      AiBasicTranslationEnabled: false,
      AdvancedTranslationEnabled: false,
      DisallowedLanguagesForTranslation: [],
      AdvancedTranslationCreativityLevel: 3,
      AdvancedTranslationVariantCount: 5,
      AdvancedTranslationConfidenceLevel: 85,
      AdvancedTranslationBrandVoicePreservation: 5,
      BasicTranslationFontAdjustmentEnabled: false,
    };
  
    const [settings, setSettings] = useState(defaultSettings);
  
    // Try to get the URL from props.context.url first
let url = props.context?.url || '';

// If URL is not set from props.context.url, then try to get it from cookies
if (!url) {
    let domainName = Cookies.get('domainName') || '';
    if (domainName) {
        // Append "Whole website" only if the domainName was retrieved from cookies
        domainName += " Whole website";
    }
    url = domainName;
} 
    const handleSettingChange = (key, value) => {
      let updatedSettings = { ...settings };
    
      if (key === 'DisallowedLanguagesForTranslation') {
        updatedSettings.DisallowedLanguagesForTranslation = value;
      } else {
        updatedSettings[key] = value;
      }
    
      setSettings(updatedSettings);
    
      // Adjusting the payload to match server expectations.
      const payload = {
        url: url, // Make sure this is the actual URL you want to update.
        data: updatedSettings
        // Removed the 'language' key since your server defaults it to "default".
      };
    
      // Assuming sendFeatureStatusToServer2 correctly handles the payload.
      sendFeatureStatusToServer2(payload).catch(error => {
        console.error(error);
        message.error('Failed to update settings.');
      });
    };




  

    function convertServerSettingsToClient(serverSettings) {
      let clientSettings = {};
    
      Object.entries(featureNameToDBField).forEach(([clientKey, serverKey]) => {
        // Check if the server setting exists and is not undefined
        if (serverSettings.hasOwnProperty(serverKey) && serverSettings[serverKey] !== undefined) {
          clientSettings[clientKey] = serverSettings[serverKey];
        }
      });
    
      return clientSettings;
    }

    useEffect(() => {
      async function loadSettings() {
        try {
          const serverSettings = await fetchSettingsFromServer(url);
          if (serverSettings) {
            const clientSettings = convertServerSettingsToClient(serverSettings);
            // Merge with defaultSettings to fill any missing keys
            setSettings({ ...defaultSettings, ...clientSettings });
            const DisallowedLanguages = serverSettings.DisallowedLanguagesForTranslation || [];
        setSettings({ ...defaultSettings, ...clientSettings, DisallowedLanguagesForTranslation: DisallowedLanguages });
          } else {
            setSettings(defaultSettings);
          }
        } catch (error) {
          console.error('Failed to fetch settings:', error);
          setSettings(defaultSettings);
        }
      }
    
      loadSettings();
    }, [url]);

    
  const renderTitle = () => {
    const titleBase = "AI Website Translation Settings";
    return url ? `${titleBase} for ${url}` : titleBase;
  };


    return (
      <div style={{ width: '98%', maxWidth: '1900px', margin: 'auto' }}>
        <div style={{ padding: '20px', display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '100%' }}>
            <Card style={{ padding: '20px 50px' }}>
              <Title level={1} align="center">{renderTitle()}</Title>
              <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'center' }}>
                <Col span={24}>
                  <Switch checked={settings.AiBasicTranslationEnabled} onChange={(checked) => handleSettingChange('AiBasicTranslationEnabled', checked)} />
                  <Text> Basic AI Website Translation</Text>
                </Col>
              </Row>
    
              {settings.AiBasicTranslationEnabled && (
                <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'center' }}>
                  <Col span={24}>
                    <Switch checked={settings.BasicTranslationFontAdjustmentEnabled} onChange={(checked) => handleSettingChange('BasicTranslationFontAdjustmentEnabled', checked)} />
                    <Text> Allow AI to adjust fonts for languages that might not display well with your website's default fonts (e.g., Chinese, Japanese, Korean, Thai, Hindi, and more).</Text>
                  </Col>
                </Row>
              )}
    
              <div style={{ height: '15px' }}></div>
              <Row gutter={[16, 24]} style={{ marginBottom: '20px', marginTop: '20px' }}>
                <Col span={24}>
                  <Card style={{ border: '1px solid #ddd', padding: '15px', display: 'flex', alignItems: 'center' }}>
                    <InfoCircleOutlined style={{ fontSize: '22px', color: '#1890ff', marginRight: '15px' }} />
                    <Text strong>
                      
You can let the AI work with all 107 languages. It will only translate your site when there is sufficient demand for another language, ensuring that translations are genuinely required. If your website is primarily in one language (e.g., English) and you wish to enhance its conversion rate without utilizing specialized Seatext AIs, you can enable this feature here fro English also — then, the translation AI will attempt improvements. Specialized models, such as the Landing Page AI, take precedence over translation AI.
                    </Text>
                  </Card>
                </Col>
              </Row>
    
              <div style={sliderStyle(settings.AiBasicTranslationEnabled)}>
                <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'center' }}>
                  <Col span={24}>
                    <Text>Which languages are allowed for translation and which are Disallowed:</Text>
                    <Transfer
                      dataSource={allLanguages}
                      titles={['Allowed Languages', 'Disallowed Languages']}
                      targetKeys={settings.DisallowedLanguagesForTranslation}
                      onChange={(nextTargetKeys) => handleSettingChange('DisallowedLanguagesForTranslation', nextTargetKeys)}
                      render={item => item.title}
                      disabled={!settings.AiBasicTranslationEnabled}
                      style={{ marginBottom: '20px' }}
                      listStyle={{
                          width: 300,
                          height: 400,
                      }}
                    />
                  </Col>
                </Row>
              </div>
    
              <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'center' }}>
                <Col span={24}>
                  <Switch checked={settings.AdvancedTranslationEnabled} onChange={(checked) => handleSettingChange('AdvancedTranslationEnabled', checked)} />
                  <Text> Advanced A/B Testing for Translations. This approach tests translations to eliminate any errors and boost your conversion rates.</Text>
                </Col>
              </Row>
    
              <div style={sliderStyle(settings.AdvancedTranslationEnabled)}>
                <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
                  <Col span={12}>
                    <Text>AI Creativity Level:</Text>
                    <Slider
                      min={1}
                      max={5}
                      step={1}
                      marks={{
                        1: 'Max Creativity',
                        2: 'High',
                        3: 'Medium',
                        4: 'Low',
                        5: 'Minimal Changes',
                      }}
                      onChange={(value) => handleSettingChange('AdvancedTranslationCreativityLevel', value)}
                      value={settings.AdvancedTranslationCreativityLevel}
                      disabled={!settings.AdvancedTranslationEnabled}
                    />
                  </Col>
                  <Col span={12} style={{ paddingLeft: '50px' }}>
                    <Text>
                      Adjust how creative the AI should be. A lower setting means the AI will generate content that's significantly different from the original, while a higher setting limits creativity to minimal changes.
                    </Text>
                  </Col>
                </Row>
    
                <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
                  <Col span={12}>
                    <Text>Number of Variants to Create:</Text>
                    <Slider
                      min={2}
                      max={8}
                      step={1}
                      marks={{
                        2: '2 Variants',
                        4: '4 Variants',
                        6: '6 Variants',
                        8: '8 Variants',
                      }}
                      onChange={(value) => handleSettingChange('AdvancedTranslationVariantCount', value)}
                      value={settings.AdvancedTranslationVariantCount}
                      disabled={!settings.AdvancedTranslationEnabled}
                    />
                  </Col>
                  <Col span={12} style={{ paddingLeft: '50px' }}>
                    <Text>
                      Choose the number of variant outputs. A lower number is suitable for sites with small traffic, while a higher number caters to websites with at least 30K visitors a month.
                    </Text>
                  </Col>
                </Row>
    
                <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
                  <Col span={12}>
                    <Text>Preserve Your Brand Voice (0-10):</Text>
                    <Slider
                      min={0}
                      max={10}
                      step={1}
                      marks={{
                        0: '0',
                        2: '2',
                        4: '4',
                        5: '5',
                        6: '6',
                        8: '8',
                        10: '10',
                      }}
                      onChange={(value) => handleSettingChange('AdvancedTranslationBrandVoicePreservation', value)}
                      value={settings.AdvancedTranslationBrandVoicePreservation}
                      disabled={!settings.AdvancedTranslationEnabled}
                    />
                  </Col>
                  <Col span={12} style={{ paddingLeft: '50px' }}>
                    <Text>
                      Should AI copy your brand voice characteristics, keeping it closer to your brand style, or have the ability to be more creative and depart from usual brand patterns? A higher value preserves your brand voice more closely.
                    </Text>
                  </Col>
                </Row>
    
                <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
                  <Col span={12}>
                    <Text>Confidence Level for A/B Testing:</Text>
                    <Slider
                      min={70}
                      max={95}
                      step={5}
                      marks={{
                        70: '70%',
                        75: '75%',
                        80: '80%',
                        85: '85% Recommended',
                        90: '90%',
                        95: '95%',
                      }}
                      onChange={(value) => handleSettingChange('AdvancedTranslationConfidenceLevel', value)}
                      value={settings.AdvancedTranslationConfidenceLevel}
                      disabled={!settings.AdvancedTranslationEnabled}
                    />
                  </Col>
                  <Col span={12} style={{ paddingLeft: '50px' }}>
                    <Text>
                      This slider adjusts the confidence level required to determine a winning variant in A/B testing. A higher confidence level means requiring more evidence (data) before making a decision, reducing the risk of false positives but potentially requiring more time and traffic.
                    </Text>
                  </Col>
                </Row>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
                    }
export default TranslationSettingsPage;
