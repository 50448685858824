import Cookies from 'js-cookie';
import { notification } from 'antd';

function getCookieDomain() {
  const hostname = window.location.hostname;
  // Check if running on localhost (development environment)
  if (hostname === "localhost" || hostname === "127.0.0.1") {
    return ''; // Cookies for localhost will not have a domain property
  } else {
    return '.seatext.com'; // Production domain, including all subdomains
  }
}
export async function sendRequest(requestData, endpoint) {
  const BASE_URL = "https://api.seatext.com:9200";
  
  // Get email and password from cookies
  let email = Cookies.get('email');
  let password = Cookies.get('password');

  // Log the email for debugging
  console.log(`Email from cookies: ${email}`);

  // Create the authorization header
  let basicAuth = btoa(`${email}:${password}`);
  
  const requestOptions = {
    method: 'POST',
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Basic ${basicAuth}` 
    },
    body: JSON.stringify(requestData)
  };
  console.log(`Sending request to ${BASE_URL}${endpoint} with headers ${JSON.stringify(requestOptions.headers)} and body ${requestOptions.body}`);
  
  try {
    let response = await fetch(`${BASE_URL}${endpoint}`, requestOptions);
    
    if (response.ok) {
      let data = await response.json();
      console.log("Server response:", data);
      return { success: true, data: data };
    } else {
      let error = await response.text();
      console.log("Error response from server:", error);
      return { success: false, error: error };
    }
  } catch (error) {
    console.error("Fetch error: ", error);
    return { success: false, error: error };
  }
}

export async function fetchJsCodeFromServer() {
  const email = Cookies.get('email');

  if (!email) {
    console.error("Error: Email not found in cookies.");
    return '';
  }

  // Encode the email before appending it to the URL
  const encodedEmail = encodeURIComponent(email);
  
  try {
    // Use the encoded email in the request
    console.log(`Fetching JS code for user: ${encodedEmail}`);
    const { success, data, error } = await sendRequest({}, `/client/handleGetJSCode?userID=${encodedEmail}`);
    
    if (success) {
      console.log("JS Code retrieval successful:", data.jsCode);
      return data.jsCode;
    } else {
      console.error("Error: Data fetch unsuccessful.", error);
      return '';
    }
  } catch (error) {
    console.error("An error occurred while fetching JS Code:", error);
    return '';
  }
}

  export async function handleUserRequest(isLogin, email, password, setUserCredentials, navigate, handleError) {
    const requestData = {};
  
    console.log(`Email from user request: ${email}`);
    Cookies.set('email', email, { 
      domain: getCookieDomain(),
      secure: true,
      sameSite: 'strict'
    });
    Cookies.set('password', password, { 
      domain: getCookieDomain(),
      secure: true,
      sameSite: 'strict'
    });
  
    const data = await sendRequest(requestData, isLogin ? "/client/login" : "/client/register");
  
    if (isLogin) {
      if (data.success) {
        setUserCredentials({ email: email, password: password });
        await checkAndSaveDomainStatus();
        navigate('/welcome', { replace: true });
      } else {
        console.log("Login Error:", data.error);
        handleError(data.error);
        // Show notification for unsuccessful login
        notification.open({
          message: 'Login Unsuccessful',
          description: 'Login unsuccessful, please try again later.',
          duration: 2.5, // Duration in seconds after which the notification will automatically close
        });
      }
    } else {
      if (data.error) {
        console.log("Registration Error:", data.error);
        handleError(data.error);
        // Show notification for unsuccessful registration
        notification.open({
          message: 'Registration Unsuccessful',
          description: 'Registration unsuccessful, please try again later.',
          duration: 2.5, // Duration in seconds
        });
      } else {
        setUserCredentials({ email: email, password: password });
        navigate('/welcome', { replace: true });
        console.log("Registration successful");
      }
    }
  }

export function logoutUser() {
  // Remove user session cookies
  Cookies.remove('email', { domain: '.seatext.com' });
  Cookies.remove('password', { domain: '.seatext.com' });

  // Log out message for debugging
  console.log("User logged out successfully.");

  // No redirection or additional logic is performed here
}

  
export async function handleURLUpdate(url) {
  const requestData = { url: url };

  const data = await sendRequest(requestData, "/client/updateURL");

  if (data.success) {
    console.log("URL update successful:", data.data);
  } else {
    console.log("Error:", data.error);
  }
}


export async function getPageList() {

  const domainStatus = await checkAndSaveDomainStatus();
  
  if (!domainStatus) {
    // If checkAndSaveDomainStatus() returns false, return the specific URL
    return ['seatext.com']; // Ensure this is in an array format
  }

  const data = await sendRequest({}, "/client/listofpages");

  if (data.success) {
    console.log("Page list retrieval successful:", data.data);
    return data.data.urls; // assuming that urls is the key in data where list of urls is stored.
  } else {
    console.log("Error:", data.error);
    return null;
  }
}


export async function sgetPageList() {

  const domainStatus = await checkAndSaveDomainStatus();
  

  if (!domainStatus) {
    // If checkAndSaveDomainStatus() returns false, return the specific URL
    return ['seatext.com']; // Ensure this is in an array format
  }
  const data = await sendRequest({}, "/client/handleListOfURLsFromURLHour");

  if (data.success) {
    console.log("Page list retrieval successful:", data.data);
    return data.data.urls; // assuming that urls is the key in data where list of urls is stored.
  } else {
    console.log("Error:", data.error);
    return null;
  }
}


// New function for the new endpoint
export async function getPageListFromSettings() {
  const domainStatus = await checkAndSaveDomainStatus();
  
  if (!domainStatus) {
    return ['seatext.com'];
  }

  const data = await sendRequest({}, "/client/listofpagessettings");

  if (data.success) {
    console.log("Page list from settings retrieval successful:", data.data);
    return data.data.urls;
  } else {
    console.log("Error:", data.error);
    return null;
  }
}

export async function getVariantsForUrl(url, language) { // add language parameter
  const requestData = { url: url, language: language }; // pass language in the request

  const data = await sendRequest(requestData, "/client/getvariantsforurl");

  if (data.success) {
    console.log("Variant retrieval successful:", data.data);
    return data.data; // assuming that the returned data contains the variants
  } else {
    console.log("Error:", data.error);
    return [];
  }
}

export async function updateVariantOnServer(dataToSend) {
  // Check if the domain is connected before proceeding.
  const domainConnected = await checkAndSaveDomainStatus();
  
  if (!domainConnected) {
    console.error("Domain is not connected. Variant changes will not be sent to the server.");
    return; // Stop the execution if the domain is not connected.
  }

  const endpoint = "/client/savevariantschanges";
  
  try {
    const response = await sendRequest(dataToSend, endpoint);
    if (response.success) {
      console.log("Variant changes saving successful:", response.data);
      return response.data;
    } else {
      console.error("Error:", response.error);
      throw new Error(response.error);
    }
  } catch (error) {
    console.error("Error sending variant changes to server:", error);
    throw error;
  }
}

export async function getLanguagesForUrl(url) {
  const requestData = { url: url };

  const data = await sendRequest(requestData, "/client/getlanguagesforurl");

  if (data.success) {
    console.log("Language retrieval successful:", data.data);
    return data.data; // assuming that the returned data contains the languages
  } else {
    console.error("Error:", data.error);
    return [];
  }
}


export async function fetchChartData(url, language) {
  // Construct the endpoint with the provided url and language parameters
  const endpoint = `/client/handleGetChartData?url=${encodeURIComponent(url)}&language=${encodeURIComponent(language)}`;

  try {
    const { success, data, error } = await sendRequest({}, endpoint);

    if (success) {
      console.log("Chart data retrieval successful:", data);
      return data; // Return the data as JSON
    } else {
      console.error("Error: Data fetch unsuccessful.", error);
      return null; // Return null to indicate an error
    }
  } catch (error) {
    console.error("An error occurred while fetching chart data:", error);
    return null; // Return null to indicate an error
  }
}

export async function deleteUserData() {
    // Check if the domain is connected before proceeding.
    const domainConnected = await checkAndSaveDomainStatus();
  
    if (!domainConnected) {
      console.error("Domain is not connected. Variant changes will not be sent to the server.");
      return; // Stop the execution if the domain is not connected.
    }
  // Get email and password from cookies
  const email = Cookies.get('email');
  const password = Cookies.get('password');

  if (!email || !password) {
    console.error("Error: Email or password not found in cookies.");
    return { success: false, error: "Authentication details not found." };
  }

  // Create the request payload
  const requestData = { email, password };

  // Send the delete request to the server
  const response = await sendRequest(requestData, "/client/deleteUserData");

  if (response.success) {
    console.log("User data deletion successful:", response.data);
    return { success: true, data: response.data };
  } else {
    console.error("Error:", response.error);
    return { success: false, error: response.error };
  }
}

export async function getMediaVariantsForUrl(url, language) {
  const requestData = { url: url, language: language }; 

  const data = await sendRequest(requestData, "/client/medialist");

  if (data.success) {
    console.log("Media variants retrieval successful. Data received from server:", data.data);
    // For debugging, log the entire array to see its structure
    console.log("Array of media variants:", JSON.stringify(data.data, null, 2));
    return data.data; // Assuming the server responds with an array of media variants
  } else {
    console.error("Error in retrieving media variants:", data.error);
    return [];
  }
}


/**
 * Send translated media information to the server.
 * 
 * @param {string} userUrl - The URL provided by the user.
 * @param {string} language - The selected language.
 * @param {string} originalMediaUrl - The original URL of the media.
 * @param {string} translatedMediaUrl - The Cloudinary URL of the translated media.
 * @returns {Promise<object>} The server response.
 */
export async function sendTranslatedMediaInfo(userUrl, language, originalMediaUrl, translatedMediaUrl) {
  const requestData = {
      userUrl,
      language,
      originalMediaUrl,
      translatedMediaUrl
  };

  const endpoint = "/client/saveTranslatedMedia"; // Replace with the actual server endpoint
  return await sendRequest(requestData, endpoint);
}


export async function sendDeleteVariantRequest(requestData) {
    // Check if the domain is connected before proceeding.
    const domainConnected = await checkAndSaveDomainStatus();
  
    if (!domainConnected) {
      console.error("Domain is not connected. Variant changes will not be sent to the server.");
      return; // Stop the execution if the domain is not connected.
    }
  const endpoint = "/client/deleteVariant2"; // Replace with the actual endpoint for variant deletion
  return await sendRequest(requestData, endpoint);
}


export async function checkAndSaveDomainStatus() {
  console.log("Starting domain status check");

  // Function to check if storage is available
  function isStorageAvailable(type) {
    try {
      const storage = window[type];
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return false;
    }
  }

  // Determine which storage method to use
  const useLocalStorage = !navigator.cookieEnabled && isStorageAvailable('localStorage');

  // Function to get item from storage
  function getItem(key) {
    if (navigator.cookieEnabled) {
      return Cookies.get(key);
    } else if (useLocalStorage) {
      return localStorage.getItem(key);
    }
    return null;
  }

  // Function to set item in storage
  function setItem(key, value) {
    if (navigator.cookieEnabled) {
      Cookies.set(key, value, { path: '/' });
    } else if (useLocalStorage) {
      localStorage.setItem(key, value);
    }
  }

  // Check if the status and domain are already saved
  const domainStatus = getItem('domainConnected');
  const domainName = getItem('domainName');

  if (domainStatus && domainName) {
    console.log("Domain status and name retrieved from storage.");
    return domainStatus === 'true';
  }

  // Retrieve credentials
  const email = getItem('email');
  const password = getItem('password');

  if (!email || !password) {
    console.error("Credentials not found. Cannot perform domain check.");
    return false;
  }

  console.log("Credentials found. Proceeding with domain check.");
  const requestData = { email, password };

  try {
    const { success, data } = await sendRequest(requestData, '/client/handleDomainCheck');
    console.log("checkAndSaveDomainStatus: Request sent.");

    if (success && data.domainConnected) {
      console.log("Domain check successful. Domain is connected.");
      setItem('domainConnected', 'true');
      if (data.domain) {
        setItem('domainName', data.domain);
        saveDomainToCookies(data.domain); // Save domain to cookies
      }
      return true;
    } else {
      console.error("Domain check unsuccessful.");
      setItem('domainConnected', 'false');
      return false;
    }
  } catch (error) {
    console.error(`Error during domain check - ${error}`);
    return false;
  }
}

export async function fetchUsageStats() {
    // Check if the domain is connected before proceeding.
    const domainConnected = await checkAndSaveDomainStatus();
  
    if (!domainConnected) {
      console.error("Domain is not connected. Variant changes will not be sent to the server.");
      return; // Stop the execution if the domain is not connected.
    }
  const email = Cookies.get('email'); // Retrieve the client's email from cookies
  if (!email) {
    console.error("Error: Email not found in cookies.");
    return { success: false, error: "Email not found in cookies." };
  }

  const endpoint = "/client/stats"; // Adjust the endpoint as necessary
  const requestData = { email }; // Include the email in the request data

  try {
    const { success, data } = await sendRequest(requestData, endpoint);
    
    if (success) {
      console.log("Stats retrieval successful:", data);
      return { success: true, data };
    } else {
      console.error("Stats retrieval failed:", data.error);
      return { success: false, error: data.error };
    }
  } catch (error) {
    console.error("Error fetching stats:", error);
    return { success: false, error };
  }
}





export async function fetchSettingsFromServer(url, language = 'default') {
  const requestData = { url, language };
  const endpoint = "/client/getAISettings"; // Adjust endpoint as needed

  console.log(` 1009 Fetching settings from server for URL: ${url} and Language: ${language}`); // Log the URL and language being requested

  try {
      const response = await sendRequest(requestData, endpoint);
      if (response.success) {
          console.log(" 1009 Settings retrieval successful:", JSON.stringify(response.data, null, 2)); // Log the detailed response data
          return response.data; // Return the entire settings object
      } else {
          console.error("Error fetching settings:", response.error);
          return null;
      }
  } catch (error) {
      console.error("Error in fetchSettingsFromServer:", error);
      return null;
  }
}

export async function sendFeatureStatusToServer2(payload) {
  // Check if the domain is connected before proceeding.
  const domainConnected = await checkAndSaveDomainStatus();
  
  if (!domainConnected) {
    console.error("Domain is not connected. Feature status update will not be sent to the server.");
    return; // Stop the execution if the domain is not connected.
  }

  const { url, data } = payload; // Destructure the payload to get the URL and data.

  const requestData = {
    url: url,
    data: data,
    language: "default", // Adjust this as necessary.
  };

  console.log("Sending feature status to server with data:", JSON.stringify(requestData, null, 2));

  const endpoint = "/client/updateFeatureStatus"; // Ensure this endpoint is correct.

  try {
    const response = await sendRequest(requestData, endpoint);
    if (response.success) {
      console.log("Feature status updated successfully:", response.data);
    } else {
      console.error("Error updating feature status:", response.error);
    }
  } catch (error) {
    console.error("Error sending feature status to server:", error);
  }
}

export async function addNewDomainToUserAccount(domain) {
    // Check if the domain is connected before proceeding.
    const domainConnected = await checkAndSaveDomainStatus();
  
    if (!domainConnected) {
      console.error("Domain is not connected. Variant changes will not be sent to the server.");
      return; // Stop the execution if the domain is not connected.
    }
  const requestData = { domain };

  // Endpoint to handle adding a new domain
  const endpoint = "/client/addDomain";
  return await sendRequest(requestData, endpoint);
}

// Function to fetch language codes and variants from the server
export async function fetchLanguageCodesAndVariants(url) {
  const requestData = {
      url: url
  };

  console.log("Fetching language codes and variants for URL:", url);

  const endpoint = "/client/getAvailableLanguageCodesAndVariants"; // Adjust this endpoint as necessary

  try {
      const response = await sendRequest(requestData, endpoint);
      if (response.success) {
          console.log("Language codes and variants fetched successfully:", response.data);
          return response.data;
      } else {
          console.error("Error fetching language codes and variants:", response.error);
          throw new Error(response.error);
      }
  } catch (error) {
      console.error("Error in fetching language codes and variants:", error);
      throw error;
  }
}






////this sends AI buttons (allow ai to create new varints) status  to server



export async function sendFeatureStatusToServer3(payload) {
  const domainConnected = await checkAndSaveDomainStatus();
  
  if (!domainConnected) {
    console.error("Domain is not connected. Feature status update will not be sent to the server.");
    return;
  }

  const { url, language, originalText, status } = payload;

  const requestData = {
    Url: url,
    Language: language,
    Data: {
      OriginalText: originalText,
      Status: status
    }
  };

  console.log("Sending feature status to server with data:", JSON.stringify(requestData, null, 2));

  const endpoint = "/client/handleAiSwitchUpdate";

  try {
    const response = await sendRequest(requestData, endpoint);
    if (response.success) {
      console.log("Feature status updated successfully:", response.data);
    } else {
      console.error("Error updating feature status:", response.error);
    }
  } catch (error) {
    console.error("Error sending feature status to server:", error);
  }
}






















export async function generateVariantsWithAI(url, language, originalText) {
  const endpoint = "/client/generateVariants";
  const requestData = {
    url,
    language,
    originalText
  };

  try {
    const { success, data } = await sendRequest(requestData, endpoint);
    
    if (success) {
      console.log("Variants generated successfully:", data);
      return { success: true, data };
    } else {
      console.error("Variant generation failed:", data.error);
      return { success: false, error: data.error };
    }
  } catch (error) {
    console.error("Error generating variants:", error);
    return { success: false, error };
  }
}



// Function to send updated or new customer profiles to the server
export async function sendCustomerProfilesToServer(profiles) {
  const endpoint = "/client/updateCustomerProfiles";

  try {
    const domainConnected = await checkAndSaveDomainStatus();
    if (!domainConnected) {
      console.error("Domain is not connected. Cannot send customer profiles.");
      return { success: false, error: "Domain not connected" };
    }

    const requestData = { profiles };
    const response = await sendRequest(requestData, endpoint);
    
    if (response.success) {
      console.log("Customer profiles updated successfully:", response.data);
      return { success: true, data: response.data };
    } else {
      console.error("Error updating customer profiles:", response.error);
      return { success: false, error: response.error };
    }
  } catch (error) {
    console.error("Error in sending customer profiles:", error);
    return { success: false, error: error.message };
  }
}


// Generate a random 8-character ID
export function generateRandomId() {
  return Math.random().toString(36).substring(2, 10);
}

// Get domain from cookies
export function getDomainFromCookies() {
  return Cookies.get('domainName');
}

// Save domain to cookies
export function saveDomainToCookies(domain) {
  Cookies.set('domainName', domain, { path: '/' });
}


export const loadCustomerProfilesFromServer = async () => {
  try {
    const baseURL = getDomainFromCookies();
    if (!baseURL) {
      throw new Error('Base URL not found in cookies');
    }

    const requestData = {
      baseURL: baseURL
    };

    const response = await sendRequest(requestData, '/client/getCustomerProfiles');

    if (response.success && response.data && Array.isArray(response.data.data)) {
      // The actual profiles are in response.data.data
      return {
        success: true,
        data: response.data.data.map(profile => ({
          id: profile.PersonalizationID, // Use PersonalizationID as the id
          autoUrl: profile.AutoURL,
          focusUrl: profile.FocusURL,
          personalizationId: profile.PersonalizationID,
          personalizationData: profile.PersonalizationData,
          personalizationPrompt: profile.PersonalizationPrompt,
          linkedinUrl: profile.LinkedinURL,
          websiteUrl: profile.WebsiteURL,
          name: profile.Name,
          familyName: profile.FamilyName,
          corporation: profile.Corporation,
          allowAIUseName: profile.AllowAIUseName,
          allowAIUseCompany: profile.AllowAIUseCompany,
          // Add any other fields that are present in the server response
        }))
      };
    } else {
      return {
        success: false,
        error: 'Invalid response structure' || response.error || 'Failed to load customer profiles'
      };
    }
  } catch (error) {
    console.error('Error loading customer profiles:', error);
    return {
      success: false,
      error: error.message || 'An unexpected error occurred'
    };
  }
};

export async function getPersonalisedVariantsForUrl(url, language, personalizationId) {
  const requestData = {
    url: url,
    language: language,
    personalizationId: personalizationId  // Add this line
  };

  console.log("Sending request with data:", requestData);

  const data = await sendRequest(requestData, "/client/getpersonalvariantsforurl");

  if (data.success) {
    console.log("Variant retrieval successful:", data.data);
    return data.data;
  } else {
    console.log("Error:", data.error);
    return [];
  }
}

export async function updatePersonalizedVariantOnServer(dataToSend, personalizationId) {
  // Check if the domain is connected before proceeding.
  const domainConnected = await checkAndSaveDomainStatus();
  
  if (!domainConnected) {
    console.error("Domain is not connected. Personalized variant changes will not be sent to the server.");
    return; // Stop the execution if the domain is not connected.
  }

  const endpoint = "/client/savepersonalizedvariantschanges";
  
  // Add personalizationId to each item in dataToSend
  const dataWithPersonalizationId = dataToSend.map(item => ({
    ...item,
    PersonalizationID: personalizationId
  }));

  try {
    const response = await sendRequest(dataWithPersonalizationId, endpoint);
    if (response.success) {
      console.log("Personalized variant changes saving successful:", response.data);
      return response.data;
    } else {
      console.error("Error:", response.error);
      throw new Error(response.error);
    }
  } catch (error) {
    console.error("Error sending personalized variant changes to server:", error);
    throw error;
  }
}

export async function deletePersonalisedVariantsForUrl(url, language, personalizationId) {
  const requestData = {
    url: url,
    language: language,
    personalizationId: personalizationId
  };

  console.log("Sending delete request with data:", requestData);

  try {
    const data = await sendRequest(requestData, "/client/handleDeletePersonalizedVariants");

    if (data.success) {
      console.log("Variant deletion successful:", data.message);
      return data;
    } else {
      console.error("Error:", data.error);
      throw new Error(data.error || "Failed to delete personalized variants");
    }
  } catch (error) {
    console.error("Error deleting personalized variants:", error);
    throw error;
  }
}