import React, { useState, useEffect } from 'react';
import { Card, Typography, Switch, Modal,Tooltip } from 'antd'; // Import Modal here
import { SettingOutlined, EditOutlined } from '@ant-design/icons';
import { LineChart } from '@carbon/charts-react';
import '@carbon/charts/styles.css';
import './cardstyle.css'
import SettingsPage2 from './SettingsPage2'; // Make sure to import the SettingsPage2 component
import TranslationSettingsPage from './Settingstranslation';
import { sendFeatureStatusToServer2 } from './utils.js';
import { fetchSettingsFromServer } from './utils.js';
import  Etrade from './etrade';
import VariantEdit from './VariantEdit';
import { Link } from 'react-router-dom'; 
import Mobile from './mobile';
import Landingpages from './Landingpages';
import { fetchLanguageCodesAndVariants } from './utils'; // Import the fetch function
import { EyeOutlined, RiseOutlined, TranslationOutlined, ForkOutlined } from '@ant-design/icons'



const { Text } = Typography;




const calculateSMA = (data, windowSize) => {
    let sma = [];
    for (let i = windowSize - 1; i < data.length; i++) {
        let sum = 0;
        for (let j = 0; j < windowSize; j++) {
            sum += data[i - j].conversionRate;
        }
        sma.push(sum / windowSize);
    }
    return sma;
};

const resampleData = (data, numberOfPoints) => {
    const binSize = Math.ceil(data.length / numberOfPoints);
    const resampledData = [];

    for (let i = 0; i < data.length; i += binSize) {
        let binViews = 0, binConversions = 0, binCount = 0;

        for (let j = i; j < i + binSize && j < data.length; j++) {
            binViews += data[j].views;
            binConversions += data[j].conversions;
            binCount++;
        }

        if (binCount > 0) {
            resampledData.push({
                day: `Day ${Math.floor((i + binSize / 2) / binSize)}`,
                views: binViews / binCount,
                conversions: binConversions / binCount,
                conversionRate: (binConversions / binViews) * 100
            });
        }
    }

    return resampledData;
};



const calculateConversionRateGrowth = (conversionData) => {
    if (conversionData.length < 15) {
        return "N/A"; // Ensure there's enough data
    }

    // Calculate average conversion rate for the first 7 days
    let first7DaysSum = 0;
    for (let i = 0; i < 7; i++) {
        first7DaysSum += conversionData[i].conversionRate;
    }
    const first7DaysAvg = first7DaysSum / 7;

    // Calculate average conversion rate for the last 7 days, excluding the very last day
    let last7DaysSum = 0;
    for (let i = conversionData.length - 8; i < conversionData.length - 1; i++) {
        last7DaysSum += conversionData[i].conversionRate;
    }
    const last7DaysAvg = last7DaysSum / 7;

    // Calculate the growth in percentage
    let growth = ((last7DaysAvg - first7DaysAvg) / first7DaysAvg) * 100;

    // Ensure growth is always positive
    growth = Math.abs(growth);

    return growth.toFixed(2); // Round to two decimal places
};

const filterLast60Days = (data) => {
    const lastIndex = data.length - 1;
    const firstIndex = Math.max(0, lastIndex - 21); // Ensure you don't go below index 0
    return data.slice(firstIndex, lastIndex + 1);
};


const URLDataCard = ({ url, conversionData, carbonOptions, conversionsColor, viewsColor, onSwitchToVariantEdit }) => {
    const defaultSettings = {
        AiABTestingEnabled: false,
        AiBasicTranslationEnabled: true,
        AdvancedTranslationEnabled: false,
        AiMobileAdaptationEnabled: false,
    ProductDescOptEnabled: false,
    LandingPagesEnabled: false,
      };
  



      
    const [settings, setSettings] = useState(defaultSettings);
    const [key, setKey] = useState(0);
    const [languageData, setLanguageData] = useState({
        languages: [],
        variants: 0
    });

    useEffect(() => {
        if (url) {
            fetchLanguageCodesAndVariants(url)
                .then(data => {
                    // Ensure that data.languages is an array
                    const safeLanguages = Array.isArray(data.languages) ? data.languages : [];
                    const safeVariants = data.variants || 0; // Provide a default value for variants
                    setLanguageData({
                        languages: safeLanguages,
                        variants: safeVariants
                    }); // Set the fetched data into state
                })
                .catch(error => {
                    console.error('Failed to load language codes and variants:', error);
                    // Set defaults in case of error
                    setLanguageData({ languages: [], variants: 0 });
                });
        }
    }, [url]); // Depend on URL to refetch when it changes



    const handleSettingChange = (key, value) => {
      let updatedSettings = { ...settings, [key]: value };
      setSettings(updatedSettings);
  
      const payload = {
        url: url,
        data: updatedSettings
      };
  
      sendFeatureStatusToServer2(payload).catch(error => {
        console.error(error);
       
      });
    };
  
    useEffect(() => {
      async function loadSettings() {
        try {
          const serverSettings = await fetchSettingsFromServer(url);
          if (serverSettings) {
            // Directly use the serverSettings without conversion
            setSettings({ ...defaultSettings, ...serverSettings });
          } else {
            setSettings(defaultSettings);
          }
        } catch (error) {
          console.error('Failed to fetch settings:', error);
          setSettings(defaultSettings);
        }
      }
  
      loadSettings();
    }, [url]);

    
  
    const [currentModal, setCurrentModal] = useState({visible: false, type: null, context: {}});
    const [translationSettingsContext, setTranslationSettingsContext] = useState({ url: null, aiName: null });
    const [settingsContext, setSettingsContext] = useState({ url: null, aiName: null });

    const formatNumber = (num) => {
        // Check if num is undefined, null, or not a number
        if (num === undefined || num === null || isNaN(num)) {
            return '0'; // Return '0' or some default value if the input is not valid
        }
        return num > 999 ? `${(num / 1000).toFixed(1)}k` : num.toString();
    };


    // Calculate the SMA window size as 20% of the data length, with a minimum of 1 and a maximum based on the data length.
    const smaWindowSize = Math.max(1, Math.floor(conversionData.chartData.length * 0.2));
    // Calculate SMA for conversion rates
    const conversionRatesSMA = calculateSMA(conversionData.chartData, smaWindowSize);
    // Calculate growth by comparing the first and last SMA values
    const smaGrowth = conversionRatesSMA.length > 1 ? conversionRatesSMA[conversionRatesSMA.length - 1] - conversionRatesSMA[0] : 0;


    const conversionRateGrowth = calculateConversionRateGrowth(conversionData.chartData);




    const showModal = (type, context = {}) => {
        setCurrentModal({visible: true, type, context});
    };
    
    const closeModal = () => {
        setCurrentModal(prev => ({...prev, visible: false}));
    };



    const convertToCarbonData = (chartData) => {
        const filteredData = filterLast60Days(chartData); // Filter data here
        const preparedChartData = filteredData.map(dataPoint => {
            let conversionRate = dataPoint.views > 0 ? (dataPoint.conversions / dataPoint.views) * 100 : 0;
            conversionRate = Math.min(conversionRate, 100);
            return { ...dataPoint, conversionRate };
        });
    
        console.log("Prepared Chart Data with conversion rates (capped at 100%):", preparedChartData);
    
        return preparedChartData.flatMap((dataPoint) => [
            {
                group: 'Views',
                date: `Day ${dataPoint.day}`,
                value: dataPoint.views,
                axis: 'left'
            },
            {
                group: 'Conversion Rate',
                date: `Day ${dataPoint.day}`,
                value: dataPoint.conversionRate,
                axis: 'right'
            },
        ]);
    };



   
        return (
<Card bordered={false} className="no-shadow-card" style={{ width: '100%' }}>
    <div className="data-container">
        <div className="top-section" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="numbers-container" style={{ width: '30%' }}>
                <div className="info-card">
                    <Tooltip title="Total number of page views, including visits by bots and every page refresh.">
                        <EyeOutlined style={{ color: 'blue', fontSize: '18px', marginRight: '10px' }} />
                        <Text style={{ color: '#696969', fontSize: '20px', fontWeight: 'bold', marginRight: '10px' }}>
                            {conversionData ? formatNumber(conversionData.views) : '0'}
                        </Text>
                        <Text style={{ color: viewsColor, maxWidth: '60%' }}>Views</Text>
                    </Tooltip>
                </div>
                <div style={{ height: '15px' }}></div>
                <div className="info-card">
                    <Tooltip title="Growth in the conversion rate over all time Seatext has worked on your website. Seatext compares the average conversion rate of the week you installed Seatext to the conversion rate of the current week and shows the difference.">
                        <RiseOutlined style={{ color: 'blue', fontSize: '18px', marginRight: '10px' }} />
                        <Text style={{ fontSize: '20px', fontWeight: 'bold', color: '#696969', marginRight: '10px' }}>
                            {conversionRateGrowth !== 'Need more data' ? `${conversionRateGrowth}%` : 'N/A'}
                        </Text>
                        <Text style={{ maxWidth: '60%' }}>Conversion Rate Growth</Text>
                    </Tooltip>
                </div>
                <div style={{ height: '15px' }}></div>
                <div className="info-card">
                    <Tooltip title="Number of languages the content is translated to for this URL.">
                        <TranslationOutlined style={{ color: 'blue', fontSize: '18px', marginRight: '10px' }} />
                        <Text style={{ fontSize: '20px', fontWeight: 'bold', color: '#696969', marginRight: '10px' }}>
                            {languageData.languages.length}
                        </Text>
                        <Text>Translated Languages</Text>
                    </Tooltip>
                </div>
                <div style={{ height: '15px' }}></div>
                <div className="info-card">
                    <Tooltip title="Total variants used for Multi-Variant Testing (MVT). You can check the variants in the Variants Editor.">
                        <ForkOutlined style={{ color: 'blue', fontSize: '18px', marginRight: '10px' }} />
                        <Text style={{ fontSize: '20px', fontWeight: 'bold', color: '#696969', marginRight: '10px' }}>
                            {languageData.variants}
                        </Text>
                        <Text>Variants in MVT Testing</Text>
                    </Tooltip>
                        </div>
                    </div>
    <div className="graph-container" style={{ width: '70%', marginRight: '20px' }}>
      {conversionData && conversionData.chartData && (
        <LineChart
          data={convertToCarbonData(conversionData.chartData)}
          options={{...carbonOptions, title: url }}
        />
      )}
    </div>
    <div style={{ height: '15px' }}></div>
  </div>


    
    {/* Empty div for spacing */}

                {/* Bottom Section */}
                <div className="bottom-section">
                    {/* New Elements based on new styled components */}
                    <div style={{ marginTop: '10px' }}>
                        <div className="div">
                            <div className="div-2">
                                <div className="div-3">
                                
                                    <div className="column">
                                    
                                        <div className="div-4">
                                            
                                             <div className="div-5">
                                                <img
                                                    loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/53e71c020134926e1ec74086b9f593068c345ace834393aefe3d4d4235493a4c?"
                                                    className="img"
                                                />
                                                <div className="div-6">General AI model - improves sales of your website</div>
                                               
                                            </div>
                                            <div className="div-10">
                                            AI automatically generates new text variants, tests them on your website, and selects the top-performing ones to boost sales and conversions. This model takes a generalized approach compared to specialized models. You can limit the model's scope in the settings
                                            </div>
                                            <div className="div-11">
                                                <img
                                                    loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/d6ee974d01af2b851c664f3820c487ef8bcb9b56f1e93895d3fc10c00bca4c67?"
                                                    className="img"
                                                />
                                                <div className="div-12">
                                                AI can improve up to 90% of website text
                                                </div>
                                            </div>
                                            <div className="div-13">
                                            <Switch checked={settings.AiABTestingEnabled} onChange={(checked) => handleSettingChange('AiABTestingEnabled', checked)} />
                                            <div 
                                        className="div-14" 
                                        onClick={() => showModal('AIABTesting', {url, aiName: 'AI improves sales of your website'})} 
                                        style={{ cursor: 'pointer', color: 'blue' }}
                                    >
                                        <SettingOutlined style={{ marginRight: '5px' }} />
                                        Settings
                                    </div> 
                                    <div 
                                        className="div-14" 
                                        onClick={onSwitchToVariantEdit}
                                        style={{ cursor: 'pointer', color: 'blue' }}
                                    >
                                        <EditOutlined style={{ marginRight: '5px' }} />
                                        AI Variants
                                    </div>
                                            </div>
            
                                            
                                        </div>
            
                                    </div>
                                    
                                    <div className="column">
                                        <div className="div-4">
                                            <div className="div-47">
                                                <img
                                                    loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/f719bfb689620f094aa181d7911a7d381ba85b5fad87cea38b6f9d56fc0245b3?"
                                                    className="img-5"
                                                />
                                                <div className="div-48">
                                                    Online-store AI product descriptions text optimisation
                                                </div>
                                                
                                            </div>
                                            <div className="div-52">
                                            This AI model continuously optimizes product descriptions, conducting A/B tests to identify and implement the most effective versions for ongoing improvement. 
                                            </div>
                                            <div className="div-42">
                                                <img
                                                    loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/d6ee974d01af2b851c664f3820c487ef8bcb9b56f1e93895d3fc10c00bca4c67?"
                                                    className="img"
                                                />
                                                <div className="div-43">
                                                This model is ideal for online stores that need to fine-tune thousands of SKU descriptions
                                                </div>
                                            </div>
                                            <div className="div-55">
                                            <Switch checked={settings.ProductDescOptEnabled} onChange={(checked) => handleSettingChange('ProductDescOptEnabled', checked)} />
                                    <div className="div-56" 
                                        onClick={() => showModal('Product Description Optimization', {url, aiName: 'Product Description Optimization'})} 
                                        style={{ cursor: 'pointer', color: 'blue' }}
                                    >
                                        <SettingOutlined style={{ marginRight: '5px' }} />
                                        Settings
                                    </div>
                                    <div 
                                        className="div-56" 
                                        onClick={onSwitchToVariantEdit}
                                        style={{ cursor: 'pointer', color: 'blue' }}
                                    >
                                        <EditOutlined style={{ marginRight: '5px' }} />
                                        AI Variants
                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>

    {/* Empty div for spacing */}
    <div style={{ height: '15px' }}></div>




                                    <div className="div-3">
                                    <div className="column">
                                        
                                       <div className="div-4">
                                            <div className="div-5">
                                            
                                                <img
                                                    loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/930fefa83e5fe67e5e859ac3b634d1212d7df40a902b28776df850855cebbebc?"
                                                    className="img"
                                                />
                                                <div className="div-17">
                                                AI Basic translation (without AB testing)
                                                </div>
                                               
                                            </div>
                                            <div className="div-21">
                                            Boost sales with a multilingual website, with basic translations in 107 languages. This AI is ideal if you have a small number of international visitors and don't need to A/B test your translations. 
                                            </div>
                                            <div className="div-31">
                                            <Switch checked={settings.AiBasicTranslationEnabled} onChange={(checked) => handleSettingChange('AiBasicTranslationEnabled', checked)} />
                                    <div 
                                        className="div-32" 
                                        onClick={() => showModal('Translation', {url, aiName: 'Advanced translation with A/B testing'})} 
                                        style={{ cursor: 'pointer', color: 'blue' }}
                                    >
                                        <SettingOutlined style={{ marginRight: '5px' }} />
                                        Settings
                                    </div>
                                    <div 
                                        className="div-32" 
                                        onClick={onSwitchToVariantEdit}
                                        style={{ cursor: 'pointer', color: 'blue' }}
                                    >
                                        <EditOutlined style={{ marginRight: '5px' }} />
                                        AI Variants
                                    </div>
                                            </div>
                                            </div>
                                            </div>
                                            
                                            <div className="column">
                                        <div className="div-4">
                                            <div className="div-47">
                                            
                                                <img
                                                    loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/a84400d7fd49f73f9790668110f6a4eec83f4e4fa161722a50a3407f39ec41f2?"
                                                    className="img"
                                                />
                                                <div className="div-24">
                                                    Advanced translation with A/B testing
                                                </div>
                                                
                                            </div>
                                            
                                            <div className="div-28">
                                            Translate your website into 107 languages and use A/B testing to identify and eliminate any errors, ensuring the most effective translations for boosting sales.
                                            </div>
                                            <div className="div-29">
                                                <img
                                                    loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/d6ee974d01af2b851c664f3820c487ef8bcb9b56f1e93895d3fc10c00bca4c67?"
                                                    className="img"
                                                />
                                                <div className="div-30">
                                                    A/B testing approach demonstrates an average increase in
                                                    conversions by 25% plus eliminate any mistakes in translation
                                                </div>
                                            </div>
                                            <div className="div-31">
                                            <Switch checked={settings.AdvancedTranslationEnabled} onChange={(checked) => handleSettingChange('AdvancedTranslationEnabled', checked)} />
                                    <div className="div-32" 
                                        onClick={() => showModal('Translation', {url, aiName: 'Advanced translation with A/B testing'})} 
                                        style={{ cursor: 'pointer', color: 'blue' }}
                                    >
                                        <SettingOutlined style={{ marginRight: '5px' }} />
                                        Settings
                                    </div>
                                    <div 
                                        className="div-32" 
                                        onClick={onSwitchToVariantEdit}
                                        style={{ cursor: 'pointer', color: 'blue' }}
                                    >
                                        <EditOutlined style={{ marginRight: '5px' }} />
                                        AI Variants
                                    </div>
                                            </div>
                                        </div>
                                        </div>
                                        </div>     
                                </div>
                                
                            



















                            <div className="div-33">
                                <div className="div-34">
                                <div className="column">
                                        <div className="div-4">
                                            <div className="div-36">
                                                <img
                                                    loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/06fec863851f296b89e335fb0a9b4a4c84672df9bfe391a6fade01766a19cbe5?"
                                                    className="img"
                                                />
                                                <div className="div-37">
                                                    AI for mobile adaptation
                                                </div>
                                                
                                            </div>
                                            <div className="div-41">
                                            AI ensures your mobile pages are concise and impactful. Automatically optimize your content for small screens to enhance mobile conversions.
                                            </div>
                                            <div className="div-42">
                                                <img
                                                    loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/d6ee974d01af2b851c664f3820c487ef8bcb9b56f1e93895d3fc10c00bca4c67?"
                                                    className="img"
                                                />
                                                <div className="div-43">
                                                AI will optimize text for mobile devices, making it shorter and more impactful
                                                </div>
                                            </div>
                                            <div className="div-44">
                                            <Switch checked={settings.AiMobileAdaptationEnabled} onChange={(checked) => handleSettingChange('AiMobileAdaptationEnabled', checked)} />
                                    <div className="div-45" 
                                        onClick={() => showModal('Mobile', {url, aiName: 'AI A/B testing for mobile adaptation'})} 
                                        style={{ cursor: 'pointer', color: 'blue' }}
                                    >
                                        <SettingOutlined style={{ marginRight: '5px' }} />
                                        Settings
                                    </div>
                                    <div 
                                        className="div-45" 
                                        onClick={onSwitchToVariantEdit}
                                        style={{ cursor: 'pointer', color: 'blue' }}
                                    >
                                        <EditOutlined style={{ marginRight: '5px' }} />
                                        AI Variants
                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="column">
                                        <div className="div-4">
                                            <div className="div-58">
                                                <img
                                                    loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/6a3ead1ce81b3887ec3682191558af7d89c4ecf89a7df8234d9c4175017a71c1?"
                                                    className="img"
                                                />
                                                <div className="div-59">
                                                    AI improves your landing pages 
                                                </div>
                                               
                                            </div>
                                            <div className="div-63">
                                                This model is created to improve your landing pages. It has limited scope comparing to main model.
                                            </div>
                                            <div className="div-64">
                                                <img
                                                    loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/d6ee974d01af2b851c664f3820c487ef8bcb9b56f1e93895d3fc10c00bca4c67?"
                                                    className="img"
                                                />
                                                <div className="div-65">
                                                    Smaller but more focused model. 
                                                </div>
                                            </div>
                                            <div className="div-66">
                                            <Switch
                                        checked={settings.LandingPagesEnabled}
                                        onChange={(checked) => handleSettingChange('LandingpagesEnabled', checked)}
                                    />
                                    <div className="div-67" 
                                        onClick={() => showModal('Landingpages', {url, aiName: 'Landingpages'})} 
                                        style={{ cursor: 'pointer', color: 'blue' }}
                                    >
                                        <SettingOutlined style={{ marginRight: '5px' }} />
                                        Settings
                                    </div>
                                    <div 
                                        className="div-67" 
                                        onClick={onSwitchToVariantEdit}
                                        style={{ cursor: 'pointer', color: 'blue' }}
                                    >
                                        <EditOutlined style={{ marginRight: '5px' }} />
                                        AI Variants
                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title={`${currentModal.context.aiName} Settings`}
                visible={currentModal.visible}
                onCancel={closeModal}
                footer={null}
                width={currentModal.type === 'Product Description Optimization' ? 1800 : 1800}
            >
                {currentModal.type === 'Product Description Optimization' && <Etrade context={currentModal.context} />}
                {currentModal.type === 'AIABTesting' && <SettingsPage2 context={currentModal.context} />}
                {currentModal.type === 'Translation' && <TranslationSettingsPage context={currentModal.context} />}
                {currentModal.type === 'Mobile' && <Mobile context={currentModal.context} />}
                {currentModal.type === 'Landingpages' && <Landingpages context={currentModal.context} />}
            </Modal>
        </Card>
    );
};

export default URLDataCard;