import React, { useEffect } from 'react';

function GlobalScript() {
  useEffect(() => {
    // Script 1: Set or get the localStorage item
    let s = localStorage.getItem('seatext_id');
    if (!s) {
      s = Math.random().toString(36).substring(7);
      localStorage.setItem('seatext_id', s);
    }
    window.globalSeatextID = s;

    // Script 2: Load an external script (seatext)
    //const scriptSeatext = document.createElement('script');
    //scriptSeatext.src = "https://login.seatext.com/seatext.js?id=96733aab-aaad-4e0e-90ec-5c899e524514";
   // scriptSeatext.async = true;
    ///document.body.appendChild(scriptSeatext);

    // Script 3: Load an external script (replain)
    const scriptReplain = document.createElement('script');
    scriptReplain.text = `
      window.replainSettings = { id: '7a9255bd-a458-453c-b78b-4a661fd0411b' };
      (function(u){var s=document.createElement('script');s.async=true;s.src=u;
      var x=document.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);
      })('https://widget.replain.cc/dist/client.js');
    `;
    document.body.appendChild(scriptReplain);

        // Injecting Microsoft Clarity script
        const clarityScript = document.createElement('script');
        clarityScript.async = true;
        clarityScript.src = 'https://www.clarity.ms/tag/k6n7mbhlpc';
        document.body.appendChild(clarityScript);
    
        // Injecting Stripe script
        const stripeScript = document.createElement('script');
        stripeScript.src = 'https://js.stripe.com/v3/buy-button.js';
        stripeScript.async = true;
        document.body.appendChild(stripeScript);
    

    // No cleanup function, the scripts will persist

  }, []); // Empty dependency array ensures this runs once when component mounts





  return <></>; // Explicitly returning a React fragment
}

export default GlobalScript;
