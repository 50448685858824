import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Typography, Checkbox, Spin } from 'antd';
import { UserCredentialsContext } from './App';
import { handleUserRequest } from './utils';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { checkAndSaveDomainStatus } from './utils';
import { LoadingOutlined } from '@ant-design/icons';

import './LoginPage.css';

function LoginPage() {
  const [emailLogin, setEmailLogin] = useState('');
  const [passwordLogin, setPasswordLogin] = useState('');
  const [emailRegister, setEmailRegister] = useState('');
  const [passwordRegister, setPasswordRegister] = useState('');
  const [showLogin, setShowLogin] = useState(true);
  const navigate = useNavigate();
  const setUserCredentials = useContext(UserCredentialsContext);
  const [agreedToLicense, setAgreedToLicense] = useState(true);
  const [isLoading, setIsLoading] = useState(false); // State to manage loadin

  function cleanEmail(email) {
    // First, trim any leading or trailing whitespace
    email = email.trim();
    
    // Then, remove any characters that are definitely not allowed in emails
    return email.replace(/[^\w!#$%&'*+/=?^`{|}~@.-]/g, '');
  }
  
  const handleError = (error) => {
    setIsLoading(false); // Stop loading on error
  };
  useEffect(() => {
    // Check if email and password cookies exist, and show the registration screen if not
    if (!Cookies.get('email') || !Cookies.get('password')) {
      setShowLogin(false);
    }
  }, []);
  async function handleLoginSubmit() {
    setIsLoading(true);
    const cleanedEmail = cleanEmail(emailLogin);
    const success = await handleUserRequest(true, cleanedEmail, passwordLogin, setUserCredentials, navigate, handleError);
    if (success) {
      await checkAndSaveDomainStatus();
      setIsLoading(false);
    }
  }
  
  async function handleRegisterSubmit() {
    setIsLoading(true);
    try {
      const cleanedEmail = cleanEmail(emailRegister);
      const success = await handleUserRequest(false, cleanedEmail, passwordRegister, setUserCredentials, navigate, handleError);
      if (success) {
        setIsLoading(false);
      }
    } catch (error) {
      handleError(error);
    }
  }
  return (
    <div className="login-page-container">
      <div className="external-content-container">
        <iframe src="https://seatext.com/registration" frameBorder="0" title="SEATEXT Registration" />
      </div>

      <div className="auth-container" data-seatextdont >
       
        <div className={`auth-section ${showLogin ? '' : 'hidden'}`}>
          <Typography.Title level={2} style={{ textAlign: 'center' }}>Sign In</Typography.Title>
          <Form onFinish={handleLoginSubmit}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input
                placeholder="Email Address"
                onChange={(e) => setEmailLogin(e.target.value)}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password
                placeholder="Password"
                onChange={(e) => setPasswordLogin(e.target.value)}
                size="large"
              />
            </Form.Item>
            <Button htmlType="submit" block style={{
  backgroundColor: '#000',
  borderColor: '#000',
  color: '#fff',
  fontWeight: 'bold',
  height: '50px',
  fontSize: '18px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}} disabled={isLoading}>
  {isLoading ? (
    <>
      <LoadingOutlined style={{ fontSize: 20, color: '#fff' }} spin />
      <span style={{ marginLeft: 8 }}>Signing In...</span>
    </>
  ) : (
    "Sign In"
  )}
</Button>
            
          </Form>
          <Button type="link" onClick={() => setShowLogin(false)}>
            Don’t have an account? Register
          </Button>
        </div>

        <div className={`auth-section ${showLogin ? 'hidden' : ''}`}>
          <Typography.Title level={2} style={{ textAlign: 'center' }}>Register</Typography.Title>
          <Form onFinish={handleRegisterSubmit}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input
                placeholder="Email Address"
                onChange={(e) => setEmailRegister(e.target.value)}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password
                placeholder="Password"
                onChange={(e) => setPasswordRegister(e.target.value)}
                size="large"
              />
            </Form.Item><Button type="primary" htmlType="submit" block style={{
  backgroundColor: '#000',
  borderColor: '#000',
  color: '#fff',
  fontWeight: 'bold',
  height: '50px',
  fontSize: '18px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}} disabled={isLoading || !agreedToLicense}>
  {isLoading ? (
    <>
      <LoadingOutlined style={{ fontSize: 20, color: '#fff' }} spin />
      <span style={{ marginLeft: 8 }}>Registering...</span>
    </>
  ) : (
    "Register"
  )}
</Button>
            

            <Form.Item name="agreement" valuePropName="checked">
              <Checkbox onChange={(e) => setAgreedToLicense(e.target.checked)}>
                I agree to the{" "}
                <Link to="/license-agreement">License Agreement</Link>
              </Checkbox>
            </Form.Item>
          </Form>
          <Button type="link" onClick={() => setShowLogin(true)}>
            Already have an account? Sign In
          </Button>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;