import React, { useState, useEffect } from 'react';
import { Typography, Card, Row, Col, Switch, Image } from 'antd';
import { sendFeatureStatusToServer2, fetchSettingsFromServer } from './utils';

const { Title, Text } = Typography;

function AIPersonalizationFeatures(props) {
  const defaultSettings = {
    TitleTaglineEnhancementEnabled: true,
    WebsiteContentEnhancementEnabled: true,
    CTAsOptimizationEnabled: true,
    MenuItemsEnhancementEnabled: true,
    CustomerReviewsEnhancementEnabled: true,
    LegalDocumentationEnhancementEnabled: false,
    TechnicalDocumentationEnhancementEnabled: true,
  };

  const [settings, setSettings] = useState(defaultSettings);
  const [modifiedUrl, setModifiedUrl] = useState('');

  useEffect(() => {
    let initialUrl = props.context?.url || '';
    setModifiedUrl(initialUrl);

    fetchSettingsFromServer(initialUrl)
      .then(serverSettings => {
        if (serverSettings) {
          setSettings({ ...defaultSettings, ...serverSettings });
        }
      })
      .catch(error => console.error('Failed to fetch settings:', error));
  }, [props.context?.url]);

  const handleSettingChange = (key, value) => {
    const updatedSettings = { ...settings, [key]: value };
    setSettings(updatedSettings);

    sendFeatureStatusToServer2({ url: modifiedUrl, data: updatedSettings })
      .catch(error => {
        console.error(error);
      });
  };

  const personalizationFeatures = [
    {
      title: "Titles and Taglines",
      key: "TitleTaglineEnhancementEnabled",
      image: "https://login.seatext.com/titles-taglines.png",
      description: "AI-powered enhancement of your titles and taglines to captivate potential customers. Our system analyzes your industry and target audience to craft compelling, attention-grabbing phrases that resonate with your prospects.",
    },
    {
      title: "Content Optimization",
      key: "WebsiteContentEnhancementEnabled",
      image: "https://login.seatext.com/website-content.png",
      description: "Leverage AI to optimize your website content for maximum impact. Our system tailors your message to address pain points and highlight benefits, increasing engagement and conversion rates among your sales prospects.",
    },
    {
      title: "Call-to-Action Enhancement",
      key: "CTAsOptimizationEnabled",
      image: "https://login.seatext.com/ctas-buttons.png",
      description: "Boost conversions with AI-optimized calls-to-action. Our system crafts persuasive CTAs that motivate your prospects to take the next step in their buyer's journey, increasing your sales pipeline.",
    },
    {
      title: "Menu Item Optimization",
      key: "MenuItemsEnhancementEnabled",
      image: "https://login.seatext.com/menu-items.png",
      description: "Enhance your menu items with AI-driven descriptions that entice and inform. Our system creates clear, appetizing text that guides your prospects through your offerings, improving their experience and increasing sales.",
    },
    {
      title: "Customer Review Enhancement",
      key: "CustomerReviewsEnhancementEnabled",
      image: "https://login.seatext.com/customer-reviews.png",
      description: "Amplify the impact of customer reviews with AI refinement. Our system enhances the language and presentation of reviews, maintaining authenticity while maximizing their persuasive power for potential customers.",
    },
    {
      title: "Legal Text Optimization",
      key: "LegalDocumentationEnhancementEnabled",
      image: "https://login.seatext.com/license-agreement-documentation.png",
      description: "Simplify and clarify legal text with AI assistance. Our system helps make your terms and conditions more accessible to prospects, reducing friction in the sales process while maintaining legal accuracy.",
    },
    {
      title: "Technical Content Enhancement",
      key: "TechnicalDocumentationEnhancementEnabled",
      image: "https://login.seatext.com/technical-documentation-1.png",
      description: "Transform complex technical information into clear, persuasive content. Our AI system tailors technical descriptions to your prospects' level of expertise, making your product benefits more accessible and compelling.",
    },
  ];

  return (
    <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
      <Card>
        <Title level={1} style={{ fontSize: '24px', fontWeight: 'bold', marginTop: '20px', marginLeft: '30px' }}>
          AI-Powered Sales Content Optimization
        </Title>

        {personalizationFeatures.map((feature, index) => (
          <Card key={feature.key} style={{ marginBottom: '20px', background: index % 2 === 0 ? '#f5f5f5' : '#fff' }}>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Image src={feature.image} alt={feature.title} />
              </Col>
              <Col span={16}>
                <Title level={3}>{feature.title}</Title>
                <Text>{feature.description}</Text>
                <div style={{ marginTop: '10px' }}>
                  <Switch
                    checked={settings[feature.key]}
                    onChange={(checked) => handleSettingChange(feature.key, checked)}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                  />
                </div>
              </Col>
            </Row>
          </Card>
        ))}
      </Card>
    </div>
  );
}

export default AIPersonalizationFeatures;