import React, { useState, useEffect } from 'react';
import "./welcome.css";
import { checkAndSaveDomainStatus} from './utils';
import { DomainStatusChecker } from './DomainStatusChecker'; // Adjust the import path as necessary
import {  useNavigate } from 'react-router-dom';
import Seatextdemo from './seatextdemo';
import AISalesPersonalisation from './AIsalespersonalisation';
import AIMarketingPersonalisation from './AIMarketingPersonalisation';
import TranslationSettingsPage from './Settingstranslation';
import Cookies from 'js-cookie'; // Import js-cookie
///import SettingsPage2 from './SettingsPage2'; // Make sure to import the SettingsPage2 component
import { PauseCircleOutlined } from '@ant-design/icons'; // Importing the icon
import { Alert } from 'antd';
import { isMobile } from 'react-device-detect';
import { WarningOutlined } from '@ant-design/icons';
import { Layout, Button, Typography, Menu, Row, Col } from 'antd';
import { UserOutlined, ShoppingOutlined, BulbOutlined, DashboardOutlined } from '@ant-design/icons';
import Lottie from 'react-lottie';
import animationData from './animation.json';

import { Routes, Route, Navigate, Link } from 'react-router-dom';

import URLDataContainer from './URLDataContainer';
import {
  DownloadOutlined,
  CreditCardOutlined,
  TranslationOutlined,
  EditOutlined,
  PieChartOutlined,
  SettingOutlined,
  GlobalOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  PictureOutlined,
  MobileOutlined,
  ShopOutlined,
  UploadOutlined,
  ApiOutlined,
  ApartmentOutlined,
  InfoCircleOutlined,
  AimOutlined,
  GoogleOutlined,
  PlayCircleOutlined,
  MailOutlined,
  AppstoreOutlined,
  BookOutlined,
} from '@ant-design/icons';

import VariantsEditPage from './VariantEdit';
import PlanSelectAndPayment from './PlanSelectAndPayment';
import SettingsPage from './SettingsPage';
import YoutubeVideoPage from './YoutubeVideoPage';
import FAQPage from './FAQPage';
import ImageUploader from './upload';
import VariantNext from './variantnext';
import BetaFeatureAccessDenied from './Beta';
import SettingsPage2 from './SettingsPage2';
import Etrade from './etrade';
import Mobile from './mobile';

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: null,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  },
  path: 'https://lottie.host/9659b047-4fd8-4fc1-a023-4922cc5be32f/BYUcavxRRb.json'
};
const { Header, Content, Sider } = Layout;
const { Title } = Typography;

export default function WelcomePage() {
  
 ///// const [isLoggedOut, setIsLoggedOut] = useState(false); // State to manage logout status
 const [selectedKey, setSelectedKey] = useState('Seatextdemo');
  const [domainName, setDomainName] = useState('');
  const [isLoading, setIsLoading] = useState(true);  // New state for loading status
  const [isDomainConnected, setIsDomainConnected] = useState(false); // Define this state for domain connection
  const navigate = useNavigate();
  const [menuMode, setMenuMode] = useState('horizontal');
  const [selectedVerticalKey, setSelectedVerticalKey] = useState('dashboard');
  // Callback function to handle domain status
  const handleDomainStatusCheck = (isConnected) => {
    setIsDomainConnected(isConnected);
  };
  // Check domain status every 60 seconds
  const checkDomainStatus = () => {
    checkAndSaveDomainStatus()
    // Assume `soamincheck` returns the current domain name or an empty string
    // Replace with the actual function call
    const newDomain = Cookies.get('domainName');
    setDomainName(newDomain);
  };

  useEffect(() => {
    checkAndSaveDomainStatus()
    // Initialize domainName from cookies
    const domain = Cookies.get('domainName');
    setDomainName(domain);
    setIsLoading(false);

    if (domain) {
      navigate("/welcome/aimanager");
    }

    // Set up an interval to check domain status every 60 seconds
    const intervalId = setInterval(checkDomainStatus, 60000); // 60 seconds

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }


  const handleMenuClick = (e) => {
    setSelectedKey(e.key);
  };


  const items = [
    {
      label: <Link to="/welcome/seatextdemo">How to Install SEATEXT</Link>,
      key: 'seatext-install',
      icon: <DownloadOutlined />,
    },
    {
      label: <Link to="/welcome/plan-selection">Subscription and Usage</Link>,
      key: 'plans-usage',
      icon: <AppstoreOutlined />,
    },
    {
      label: 'Settings',
      key: 'settings',
      icon: <SettingOutlined />,
      
          label: <Link to="/welcome/settings">General Settings</Link>,
        
        
      
    },
    {
      label: (
        <a href="https://seatext.com/documentation" target="_blank" rel="noopener noreferrer">
          Documentation
        </a>
      ),
      key: 'documentation',
      icon: <BookOutlined />,
    },
  ];







  const menuItems = [

    
    {
      key: 'ai-translation',
      icon: <TranslationOutlined />,
      label: <Link to="/welcome/TranslationSettingsPage">Website Translation</Link>,
    },
    {
      key: 'aimanager',
      icon: <PieChartOutlined />,
      label: <Link to="/welcome/aimanager">AI manager</Link>,
    },
    {
      key: 'aiImprovesConversion',
      icon: <PieChartOutlined />,
      label: <Link to="/welcome/SettingsPage2">AI Conversion Optimization</Link>,
    },
    {
      key: 'variantEdit',
      icon: <EditOutlined />,
      label: <Link to="/welcome/variant-edit">Edit Variants</Link>, 
    },
    {
      key: 'AIsales',
      icon: <ShoppingOutlined />,
      label: <Link to="/welcome/AIsales">AISalesPersonalisation</Link>,
    },
    {
      key: 'aiMobile',
      icon: <MobileOutlined />,
      label: <Link to="/welcome/ai-mobile">Mobile Adaptation</Link>,
    },
    {
      key: 'aiProduct',
      icon: <ShopOutlined />,
      label: <Link to="/welcome/ai-product">E-Store Optimization</Link>,
    },


    {
      key: 'AIMarketingPersonalisation',
      icon: <BulbOutlined />,
      label: <Link to="/welcome/AIMarketingPersonalisation">AIMarketingPersonalisation</Link>,
    },

    {
      key: 'imageUploader',
      icon: <PictureOutlined />,
      label: <Link to="/welcome/image-upload">Image Translation</Link>,
    },

  ];

  

  ///function handleLogout() {
  ///  logoutUser();
  ///  setIsLoggedOut(true); // Update state to indicate user is logged out



  ///}

 /// if (isLoggedOut) {
 /////   return (
  ///    <div style={{ width: '100%', height: '100vh', backgroundColor: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  ///      <h1>User Logged Off</h1>
  //    </div>
  ///  );
 /// }

///   <Button
///type="text"
///icon={<LogoutOutlined />}
///style={{ fontSize: '20px', color: '#000', background: '#fff' }} // Set background to white
////onClick={handleLogout}
///>
////Logout
////</Button>


const handleMenuClick2 = (e) => {
  setSelectedKey(e.key);
};

const handleDesktopModeConfirmation = () => {
  sessionStorage.setItem('desktopMode', 'true');
  window.location.reload();
};

const desktopMode = sessionStorage.getItem('desktopMode') === 'true';

if (isLoading) {
  return <div>Loading...</div>;
}
if (isMobile && !desktopMode) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', padding: '10px', textAlign: 'left', marginTop: '150px'}}>
      <div style={{ maxWidth: '800px', width: '100%' }}>
        <Alert
          message={(
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px',marginTop: '150px'}}>
              <WarningOutlined style={{ fontSize: '24px', marginRight: '10px', marginRight: '10px' }} />
              <Typography.Title level={2} style={{ fontSize: '20px', margin: 0 }}>Important Notice</Typography.Title>
            </div>
          )}
          description={
            <Typography.Paragraph style={{ fontSize: '16px', lineHeight: 1.5 }}>
              We apologize for the inconvenience, but the Seatext AI customer interface is currently not optimized for mobile devices. However, the AI itself works properly on mobile.
            </Typography.Paragraph>
          }
          type="warning"
          showIcon
          style={{ width: '100%' }}
        />
        <div style={{ height: '20px' }}></div> {/* Divider */}
        <Alert
          description={
            <div style={{ padding: '10px' }}>
              <Typography.Paragraph style={{ fontSize: '16px', lineHeight: 1.5 }}>
                For the best experience, please switch to a PC or Mac with a larger screen. Alternatively, you can switch your mobile browser to desktop mode and use your device in horizontal orientation.
                <br /><br />
                <Typography.Text strong>Instructions:</Typography.Text>
                <br />
                <Typography.Text underline>Mobile Safari:</Typography.Text>
                <ul>
                  <li>Tap the "aA" icon in the address bar.</li>
                  <li>Select "Request Desktop Website".</li>
                  <li>Rotate your device to horizontal mode.</li>
                </ul>
                <Typography.Text underline>Mobile Chrome:</Typography.Text>
                <ul>
                  <li>Tap the three dots menu in the top-right corner.</li>
                  <li>Check the box next to "Desktop site".</li>
                  <li>Rotate your device to horizontal mode.</li>
                </ul>
                <br />
                Once you have done this, click the button below to continue.
                <br /><br />
                <Button type="primary" onClick={handleDesktopModeConfirmation}>Yes, I switched to Desktop Mode</Button>
                <br /><br />
                We will send you a reminder email to log in using a PC or Mac. Thank you for your understanding and patience.
              </Typography.Paragraph>
            </div>
          }
          type="info"
          showIcon={false}
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );
}
  return (
<Layout style={{ minHeight: '100vh' }}>
<Header style={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '80px',
    background: '#ffffff',
    padding: '0',  // Remove padding
    boxShadow: 'none',
    border: 'none'
}}>
  <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
    <div style={{ width: '300px', height: '80px', marginLeft: '-20px' }}>
      <Lottie 
        options={defaultOptions}
        style={{ transform: 'scale(0.8)' }}
      />
    </div>
    <Typography.Title style={{
      lineHeight: '80px',
      margin: 0,
      whiteSpace: 'nowrap'
    }}>
      {domainName ? 
        <span style={{
          fontSize: '20px',
          fontWeight: 'normal',
          color: '#262626'
        }}>
          {` ${domainName}`}
        </span>
        :
        <span style={{
          fontSize: '20px',
          fontWeight: 'normal',
          color: '#262626'
        }}>
          DEMO. Install on your website to start.
        </span>
      }
    </Typography.Title>
  </div>
  <Menu
    mode="horizontal"
    onClick={handleMenuClick}
    selectedKeys={[selectedKey]}
    items={items}
    className="horizontal-menu"
    style={{ marginRight: '20px' }} 
  />
</Header>
  <Layout>

{/* Empty div for spacing */}
<div style={{ height: '15px' }}></div>



<Sider width={400} height='100%' style={{ background: '#fff', position: 'relative', zIndex: 0, padding: '0 15px' }}>


{/* Empty div for spacing */}
<div style={{ height: '15px' }}></div>



{/* Empty div for spacing */}
<div style={{ height: '15px' }}></div>





{/* Empty div for spacing */}
<div style={{ height: '15px' }}></div>

  {/* AI Manager Card */}
  <Link to="/welcome/aimanager" className="frame-180 clip-contents">
    <div className="frame-1651">
      <div className="frame-1641">
        <ApartmentOutlined className="icon-style" /> {/* Using Ant Design Icons */}
        <p className="ai-manager">Main AI Hub</p>
      </div>
      <p className="some-description-some-d-1">Assign specific AI models to different pages</p>
    </div>
  </Link>


  {/* Empty div for spacing */}
  <div style={{ height: '15px' }}></div>

{/* Variant Edit Card */}
<Link to="/welcome/variant-edit" className="frame-180 clip-contents"> 
  <div className="frame-1658">
    <div className="frame-1648">
      <EditOutlined className="icon-style" /> {/* Icon in blue */}
      <p className="imagen-translation-man">Variants Editor</p> {/* Title in black, capitalized */}
    </div>
    <p className="some-description-some-d-9"> Allows to edit AI variants in any language, facilitating dynamic content optimization.</p>
  </div>
</Link>





{/* Empty div for spacing */}
<div style={{ height: '15px' }}></div>

{/* E-store Optimization Card */}
<Link to="/welcome/ai-product" className="frame-180 clip-contents">
  <div className="frame-1656">
    <div className="frame-1646">
      <ShopOutlined className="icon-style" /> {/* Using Ant Design Icons */}
      <p className="estore-optimization">AI for Online Stores</p>
    </div>
    <p className="some-description-some-d-6"> AI model refines product descriptions for maximum conversions on your product pages</p>
  </div>
</Link>








{/* Empty div for spacing */}
<div style={{ height: '15px' }}></div>

  {/* AI A/B Testing Card */}
  <Link to="/welcome/SettingsPage2" className="frame-180 clip-contents">
    <div className="frame-1652">
      <div className="frame-1642">
        <AimOutlined  className="icon-style" /> {/* Using Ant Design Icons */}
        <p className="ai-abtesting">AI improves website conversion rate</p>
      </div>
      <p className="some-description-some-d-2">General AI model for any website enhances conversion rates over time</p>
    </div>
  </Link>

{/* Empty div for spacing */}
<div style={{ height: '15px' }}></div>

  {/* AI Website Translation Card */}
  <Link to="/welcome/TranslationSettingsPage" className="frame-180 clip-contents">
    <div className="frame-1653">
      <div className="frame-1643">
        <TranslationOutlined className="icon-style" /> {/* Using Ant Design Icons */}
        <p className="ai-website-translation">AI Website Translation</p>
      </div>
      <p className="some-description-some-d-3">Ai model automaticaly translates your website into 50 languages and use A/B testing to identify the best translations</p>
    </div>
  </Link>

{/* Empty div for spacing */}
<div style={{ height: '15px' }}></div>

  {/* AIsales Card */}
  <Link to="/welcome/Aisales" className="frame-180 clip-contents">
  <div className="frame-1653">
    <div className="frame-1643">
      <UserOutlined className="icon-style" /> {/* Using Ant Design Icons */}
      <p className="ai-seo">AI adapt pages for sales prospects</p>
    </div>
    <p className="some-description-some-d-4">AI personalize prospect experience</p>
  </div>
</Link>


{/* Empty div for spacing */}
<div style={{ height: '15px' }}></div>

{/* AI Mobile Adaptation Card */}
<Link to="/welcome/ai-mobile" className="frame-180 clip-contents">
  <div className="frame-1655">
    <div className="frame-1645">
      <MobileOutlined className="icon-style" /> {/* Using Ant Design Icons */}
      <p className="ai-mobile-adaptation">AI Mobile Optimization</p>
    </div>
    <p className="some-description-some-d-5">AI insures your mobile pages are concise and impactful</p>
  </div>
</Link>

{/* Empty div for spacing */}
<div style={{ height: '15px' }}></div>

{/* AI Marketing Card */}
<Link to="/welcome/AIMarketingPersonalisation" className="frame-180 clip-contents">
  <div className="frame-1655">
    <div className="frame-1645">
      <BulbOutlined className="icon-style" /> {/* Using Ant Design Icons */}
      <p className="ai-mobile-adaptation">AI personalize landing pages</p>
    </div>
    <p className="some-description-some-d-5">AI personalize landing pages based on traffic source and data about the visitor</p>
  </div>
</Link>





{/* Empty div for spacing */}
<div style={{ height: '15px' }}></div>




{/* Image Uploader Card */}
<Link to="/welcome/image-upload" className="frame-180 clip-contents">
  <div className="frame-1658">
    <div className="frame-1648">
      <UploadOutlined className="icon-style" /> {/* Icon in blue */}
      <p className="imagen-translation-man">Video and Image Uploader</p> {/* Title in black, capitalized */}
    </div>
    <p className="some-description-some-d-1">Allows to upload pictures to the translated version of the website, enhancing content localization.</p>
  </div>
</Link>







        </Sider>



        <Content style={{ padding: '24px', minHeight: '280px', background: '#ffffff' }}>
        <Routes>
  <Route path="/" element={<Navigate replace to="Seatextdemo" />} />
  
  <Route path="plan-selection" element={<PlanSelectAndPayment />} />
  <Route path="variant-edit" element={<VariantsEditPage/>} />

  <Route path="settings" element={<SettingsPage/>} />
  
  <Route path="learn" element={<YoutubeVideoPage />} />
  <Route path="faq" element={<FAQPage />} />
 
  <Route path="/TranslationSettingsPage" element={<TranslationSettingsPage  />} />
  <Route path="/SettingsPage2" element={<SettingsPage2  />} />
  <Route path="aimanager" element={<VariantNext />} />
  
 

  <Route path="AIsales" element={<AISalesPersonalisation /> } />


  <Route path="ai-mobile" element={<Mobile />} />
 
  <Route path="ai-product" element={<Etrade /> } />

  <Route path="beta" element={<SettingsPage2/> } />

  <Route path="image-upload" element={<ImageUploader />} />
  <Route path="Seatextdemo" element={<Seatextdemo />} />
  <Route path="AIMarketingPersonalisation" element={<AIMarketingPersonalisation />} />
</Routes>
          </Content>
        </Layout>
      </Layout>
 
  );
}