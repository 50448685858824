import React, { useState, useEffect } from 'react';
import { Typography, Card, Row, Col, Slider, Button, message, Switch, Alert  } from 'antd';
import { sendRequest } from './utils'; // Adjust the import path as necessary
import Cookies from 'js-cookie';
import { sendFeatureStatusToServer2,fetchSettingsFromServer } from './utils';
import { InfoCircleOutlined } from '@ant-design/icons';
////import styles from './style2.css'; // Adjust the path as necessary

const { Title, Text } = Typography;










  function SettingsPage2(props) {
    const defaultSettings = {
    AiABTestingEnabled: true,
      ABtestingCreativityLevel: 3,
      ABtestingVariantsTnumber: 5,
      ABtestingConfidenceLevel: 85,
      ABtestingEarlyElimination: 65,
      ABtestingBrandVoicePreservation: 5,
      ABtestingTrafficPercentageForVariants: 1,
      TitleTaglineEnhancementEnabled:true,
WebsiteContentEnhancementEnabled:true,
CTAsOptimizationEnabled:true,
MenuItemsEnhancementEnabled:true,
CustomerReviewsEnhancementEnabled:true,
LegalDocumentationEnhancementEnabled:false,
TechnicalDocumentationEnhancementEnabled:true,
    };
    const [settings, setSettings] = useState(defaultSettings);
    const [showAiABTestSwitch, setShowAiABTestSwitch] = useState(false);
    const [modifiedUrl, setModifiedUrl] = useState('');
  
    useEffect(() => {
      let initialUrl = props.context?.url || '';
      let isUrlFromCookies = false;
  
      if (!initialUrl) {
        const domainName = Cookies.get('domainName');
        if (domainName) {
          initialUrl = `${domainName} Whole website`;
          setShowAiABTestSwitch(true);
          isUrlFromCookies = true;
        }
      } else {
        setShowAiABTestSwitch(false);
      }
  
      setModifiedUrl(initialUrl); // Update the state with the modified URL
  
      async function loadSettings() {
        try {
          const serverSettings = await fetchSettingsFromServer(initialUrl);
          if (serverSettings) {
            setSettings({ ...defaultSettings, ...serverSettings });
          }
        } catch (error) {
          console.error('Failed to fetch settings:', error);
        }
      }
  
      loadSettings();
  
      return () => {
        if (isUrlFromCookies) {
          // Perform cleanup or additional actions when the component unmounts or URL changes
        }
      };
    }, [props.context?.url]); // Depend on `props.context.url` to re-run this effect when it changes
  
    const handleSettingChange = (key, value) => {
      const updatedSettings = { ...settings, [key]: value };
      setSettings(updatedSettings);
  
      const payload = {
        url: modifiedUrl, // Use the modified URL for server requests
        data: updatedSettings,
      };
  
      sendFeatureStatusToServer2(payload).catch(error => {
        console.error(error);
        message.error('Failed to update settings.');
      });
    };
  
    const renderTitle = () => {
      const titleBase = "AI improves conversion rate settings";
      return modifiedUrl ? `${titleBase} for ${modifiedUrl}` : titleBase;
    };
  




  return (
    <div>
    <div style={{ width: '1400px', maxWidth: '1900px', minWidth: '1200px' }}>
      <div style={{ padding: '20px', display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '100%' }}>
          <Card style={{ padding: '20px 50px' }}>
          <Title 
      level={1} 
      align="left" 
      style={{ 
        fontSize: '24px', 
        fontWeight: 'bold',
        marginTop: '20px',  // Adds vertical spacing
        marginLeft: '30px'  // Adds horizontal spacing to the left
      }}
    >{renderTitle()}</Title>


<Alert
  message="Important Information"
  description={
    <>
      This page allows you to control the scope of AI—determining what text AI can and cannot fine-tune. While there are several methods to limit AI scope, we recommend using the `sea`textdont` and 'sea`textonly' commands as the most effective approach. You can find detailed information about these commands in our documentation at <a href="https://seatext.com/documentation-ai-scope" target="_blank" rel="noopener noreferrer">https://seatext.com/documentation-ai-scope</a>. We strongly advise using these methods first. The sliders below should only be used if you cannot edit your website directly or if you don't have time to manually create these tags.
    </>
  }
  type="info"
  showIcon
  icon={<InfoCircleOutlined />}
  style={{ marginBottom: '20px' }}
/>

  
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", gap: 10 }}>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexGrow: 0,
      flexShrink: 0,
      position: "relative",
      background: "#fff",
    }}
  >
    <div
      style={{
        flexGrow: 0,
        flexShrink: 0,
        width: 1086,
        height: 280,
        position: "relative",
        background: "#f5f5f5",
      }}
    >
      <div
        style={{
          width: 1086,
          height: 280,
          position: "absolute",
          left: "-0.5px",
          top: "-0.5px",
          background: "#f5f5f5",
        }}
      />
      <div
        style={{
          width: 354,
          height: 224,
          position: "absolute",
          left: 28,
          top: 28,
          overflow: "hidden",
          borderRadius: 16,
          borderWidth: 1,
          borderColor: "#000",
        }}
      >
        <img
           src="https://login.seatext.com/titles-taglines.png"
          style={{
            width: 354,
            height: 224,
            position: "absolute",
            left: "-0.5px",
            top: "-0.5px",
            objectFit: "cover",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          position: "absolute",
          left: 1010,
          top: 28,
          gap: 5,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 1,
          paddingBottom: 1,
          borderRadius: 16,
          background: "#1890ff",
        }}
      >
        <Switch
          checked={settings.TitleTaglineEnhancementEnabled}
          onChange={(checked) => handleSettingChange('TitleTaglineEnhancementEnabled', checked)}
           
           disabled={false}   checkedChildren="ON"
        unCheckedChildren="OFF"
          icon={false}
          loading={false}
          size="medium"
          text
        />
      </div>
      <p
        style={{
          width: 468,
          position: "absolute",
          left: 438,
          top: 32,
          fontSize: 22,
          textAlign: "left",
          color: "rgba(0,0,0,0.85)",
        }}
      >
        Titles, Taglines, and Short Phrases
      </p>
      <p
        style={{
          width: 464,
          position: "absolute",
          left: 438,
          top: 60,
          fontSize: 16,
          textAlign: "left",
          color: "rgba(0,0,0,0.45)",
        }}
      >
        Enhance your website's titles, taglines, and short phrases with AI. This feature uses AI's
        contextual understanding to complement your content. Our AI tailors its suggestions based on
        your industry and the copy on your website. While AI can provide valuable insights, some
        suggestions may require manual review and adjustments to ensure optimal results.
      </p>
    </div>
    <div
      style={{
        flexGrow: 0,
        flexShrink: 0,
        width: 1086,
        height: 280,
        position: "relative",
        background: "#fff",
      }}
    >
      <div
        style={{
          width: 1086,
          height: 280,
          position: "absolute",
          left: "-0.5px",
          top: "-0.5px",
          background: "#fff",
        }}
      />
      <img
         src="https://login.seatext.com/website-content.png"
        style={{
          width: 354,
          height: 224,
          position: "absolute",
          left: "27.5px",
          top: "27.5px",
          objectFit: "cover",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          position: "absolute",
          left: 1010,
          top: 28,
          gap: 5,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 1,
          paddingBottom: 1,
          borderRadius: 16,
          background: "#1890ff",
        }}
      >
        <Switch
          checked={settings.WebsiteContentEnhancementEnabled}
          checkedChildren="ON"
          unCheckedChildren="OFF"
          onChange={(checked) => handleSettingChange('WebsiteContentEnhancementEnabled', checked)}
           
           disabled={false}  
    
          icon={false}
          loading={false}
          size="medium"
          text
        />
      </div>
      <p
        style={{
          width: 462,
          position: "absolute",
          left: 438,
          top: 28,
          fontSize: 22,
          textAlign: "left",
          color: "rgba(0,0,0,0.85)",
        }}
      >
        Website Content Enhancement
      </p>
      <p
        style={{
          width: 464,
          position: "absolute",
          left: 438,
          top: 56,
          fontSize: 16,
          textAlign: "left",
          color: "rgba(0,0,0,0.45)",
        }}
      >
        Enhance your website content using AI to boost user engagement. Adjust the AI Creativity
        Level to match your brand’s voice and tone. Our AI will generate at least six different
        variants and track their performance through extensive A/B testing. It will continue to
        create new versions until it identifies the most effective one, ensuring the content not
        only resonates with your audience but also reinforces your brand identity.
      </p>
    </div>
    <div
      style={{
        flexGrow: 0,
        flexShrink: 0,
        width: 1086,
        height: 280,
        position: "relative",
        background: "#f5f5f5",
      }}
    >
      <div
        style={{
          width: 1086,
          height: 280,
          position: "absolute",
          left: "-0.5px",
          top: "-0.5px",
          background: "#f5f5f5",
        }}
      />
      <img
         src="https://login.seatext.com/ctas-buttons.png"
        style={{
          width: 354,
          height: 224,
          position: "absolute",
          left: "27.5px",
          top: "27.5px",
          objectFit: "cover",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          position: "absolute",
          left: 1010,
          top: 28,
          gap: 5,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 1,
          paddingBottom: 1,
          borderRadius: 16,
          background: "#1890ff",
        }}
      >
        <Switch
          checked={settings.CTAsOptimizationEnabled}
          onChange={(checked) => handleSettingChange('CTAsOptimizationEnabled', checked)}
           
           disabled={false}   checkedChildren="ON"
        unCheckedChildren="OFF"
          icon={false}
          loading={false}
          size="medium"
          text
        />
      </div>
      <p
        style={{
          width: 462,
          position: "absolute",
          left: 438,
          top: 28,
          fontSize: 22,
          textAlign: "left",
          color: "rgba(0,0,0,0.85)",
        }}
      >
        Calls-to-Action (CTAs) and Buttons
      </p>
      <p
        style={{
          width: 464,
          position: "absolute",
          left: 438,
          top: 76,
          fontSize: 16,
          textAlign: "left",
          color: "rgba(0,0,0,0.45)",
        }}
      >
        AI rewrites calls to action and buttons based on your website's content and industry. This
        feature enables you to A/B test multiple options, helping you determine the most effective
        CTAs to convert your target audience and buyer personas.
      </p>
    </div>
    <div
      style={{
        flexGrow: 0,
        flexShrink: 0,
        width: 1086,
        height: 280,
        position: "relative",
        background: "#fff",
      }}
    >
      <div
        style={{
          width: 1086,
          height: 280,
          position: "absolute",
          left: "-0.5px",
          top: "-0.5px",
          background: "#fff",
        }}
      />
      <div style={{ width: 354, height: 224, position: "absolute", left: 28, top: 28 }}>
        <img
           src="https://login.seatext.com/menu-items.png"
          style={{
            width: 354,
            height: 224,
            position: "absolute",
            left: "-0.5px",
            top: "-0.5px",
            objectFit: "cover",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          height: 21,
          position: "absolute",
          left: 1010,
          top: 28,
          gap: 5,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 1,
          paddingBottom: 1,
          borderRadius: 16,
          background: "#1890ff",
        }}
      >
        <Switch
          checked={settings.MenuItemsEnhancementEnabled}
          onChange={(checked) => handleSettingChange('MenuItemsEnhancementEnabled', checked)}
           
           disabled={false}   checkedChildren="ON"
        unCheckedChildren="OFF"
          icon={false}
          loading={false}
          size="medium"
          text
        />
      </div>
      <p
        style={{
          width: 462,
          position: "absolute",
          left: 442,
          top: 28,
          fontSize: 22,
          textAlign: "left",
          color: "rgba(0,0,0,0.85)",
        }}
      >
        Menu items
      </p>
      <p
        style={{
          width: 464,
          position: "absolute",
          left: 442,
          top: 56,
          fontSize: 16,
          textAlign: "left",
          color: "rgba(0,0,0,0.45)",
        }}
      >
        Utilize AI to refine your menu item descriptions, making them more engaging and clear. Due
        to the concise nature of menu texts, it is essential to prioritize accuracy. We strongly
        recommend monitoring the suggested variants in the Variant Editor. Supervision is crucial as
        this text experiment progresses to ensure the precision and appeal of each entry.
      </p>
    </div>
    <div
      style={{
        flexGrow: 0,
        flexShrink: 0,
        width: 1086,
        height: 280,
        position: "relative",
        background: "#f5f5f5",
      }}
    >
      <div
        style={{
          width: 1086,
          height: 280,
          position: "absolute",
          left: "-0.5px",
          top: "-0.5px",
          background: "#f6f6f6",
        }}
      />
      <img
         src="https://login.seatext.com/customer-reviews.png"
        style={{
          width: 354,
          height: 224,
          position: "absolute",
          left: "27.5px",
          top: "27.5px",
          objectFit: "cover",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          position: "absolute",
          left: 1010,
          top: 28,
          gap: 5,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 1,
          paddingBottom: 1,
          borderRadius: 16,
          background: "#1890ff",
        }}
      >
        <Switch
          checked={settings.CustomerReviewsEnhancementEnabled}
          onChange={(checked) => handleSettingChange('CustomerReviewsEnhancementEnabled', checked)}
           
           disabled={false}   checkedChildren="ON"
        unCheckedChildren="OFF"
          icon={false}
          loading={false}
          size="medium"
          text
        />
      </div>
      <p
        style={{
          width: 462,
          position: "absolute",
          left: 438,
          top: 28,
          fontSize: 22,
          textAlign: "left",
          color: "rgba(0,0,0,0.85)",
        }}
      >
        Customer reviews
      </p>
      <p
        style={{
          width: 464,
          position: "absolute",
          left: 438,
          top: 56,
          fontSize: 16,
          textAlign: "left",
          color: "rgba(0,0,0,0.45)",
        }}
      >
        Our AI technology refines customer reviews by improving language, correcting grammatical
        errors, and moderating tone. It enhances the readability and professionalism of the reviews
        while preserving the original sentiment. Please note that if you wish to remove negative
        reviews, this must be done manually.
      </p>
    </div>
    <div
      style={{
        flexGrow: 0,
        flexShrink: 0,
        width: 1086,
        height: 280,
        position: "relative",
        background: "#fff",
      }}
    >
      <div
        style={{
          width: 1086,
          height: 280,
          position: "absolute",
          left: "-0.5px",
          top: "-0.5px",
          background: "#fff",
        }}
      />
      <img
         src="https://login.seatext.com/license-agreement-documentation.png"
        style={{
          width: 354,
          height: 224,
          position: "absolute",
          left: "27.5px",
          top: "27.5px",
          objectFit: "cover",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          position: "absolute",
          left: 1010,
          top: 28,
          gap: 5,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 1,
          paddingBottom: 1,
          borderRadius: 16,
          background: "#1890ff",
        }}
      >
        <Switch
          checked={settings.LegalDocumentationEnhancementEnabled}
          onChange={(checked) => handleSettingChange('LegalDocumentationEnhancementEnabled', checked)}
           
           disabled={false}   checkedChildren="ON"
        unCheckedChildren="OFF"
          icon={false}
          loading={false}
          size="medium"
          text
        />
      </div>
      <p
        style={{
          width: 462,
          position: "absolute",
          left: 438,
          top: 28,
          fontSize: 22,
          textAlign: "left",
          color: "rgba(0,0,0,0.85)",
        }}
      >
        License Agreements 
      </p>
      <p
        style={{
          width: 464,
          position: "absolute",
          left: 438,
          top: 76,
          fontSize: 16,
          textAlign: "left",
          color: "rgba(0,0,0,0.45)",
        }}
      >
        AI can assist in reviewing and suggesting revisions to license agreements and other legal
        documents. However, due to the critical nature of these documents, we strongly advise
        against activating this feature without thorough oversight. If you decide to use this
        capability, it should be limited to preliminary drafts or non-essential documents. Ensure
        that all AI-generated modifications are meticulously reviewed to maintain legal accuracy and
        compliance.
      </p>
    </div>
    <div
      style={{
        flexGrow: 0,
        flexShrink: 0,
        width: 1086,
        height: 280,
        position: "relative",
        background: "#f6f6f6",
      }}
    >
      <div
        style={{
          width: 1086,
          height: 280,
          position: "absolute",
          left: "-0.5px",
          top: "-0.5px",
          background: "#f6f6f6",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          position: "absolute",
          left: 1010,
          top: 28,
          gap: 5,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 1,
          paddingBottom: 1,
          borderRadius: 16,
          background: "#1890ff",
        }}
      >
        <Switch
          checked={settings.TechnicalDocumentationEnhancementEnabled}
          onChange={(checked) => handleSettingChange('TechnicalDocumentationEnhancementEnabled', checked)}
           
           disabled={false}   checkedChildren="ON"
        unCheckedChildren="OFF"
          icon={false}
          loading={false}
          size="medium"
          text
        />
      </div>
      <p
        style={{
          width: 462,
          position: "absolute",
          left: 440,
          top: 28,
          fontSize: 22,
          textAlign: "left",
          color: "rgba(0,0,0,0.85)",
        }}
      >
        Technical Documentation
      </p>
      <p
        style={{
          width: 464,
          position: "absolute",
          left: 440,
          top: 58,
          fontSize: 16,
          textAlign: "left",
          color: "rgba(0,0,0,0.45)",
        }}
      >
        Our AI can assist in refining the presentation of technical information on your website. It
        carefully rephrases content to improve clarity and understanding, which is particularly
        beneficial for software companies and other tech-focused entities. This AI feature focuses
        on enhancing the wording without altering the original technical details. It also corrects
        grammatical errors and optimizes the text for better readability. By implementing A/B
        testing, we can determine which version of the technical description resonates best.
      </p>
      <img
         src="https://login.seatext.com/technical-documentation-1.png"
        style={{
          width: 354,
          height: 224,
          position: "absolute",
          left: "27.5px",
          top: "27.5px",
          objectFit: "cover",
        }}
      />
    </div>
  </div>
</div>


<div style={{ height: '15px' }}></div>


            {/* AI Creativity Level and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>AI Creativity Level:</Text>
                <Slider
                  min={1}
                  max={5}
                  step={1}
                  marks={{
                    1: 'Max',
                    2: 'High',
                    3: 'Medium',
                    4: 'Low',
                    5: 'Min',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingCreativityLevel', value)}
                  value={settings.ABtestingCreativityLevel}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Adjust how creative the AI should be. A lower setting means the AI will generate content that's significantly different from the original, while a higher setting limits creativity to minimal changes.
                </Text>
              </Col>
            </Row>
  
            {/* Number of Variants to Create and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Number of Variants to Create:</Text>
                <Slider
                  min={2}
                  max={8}
                  step={1}
                  marks={{
                   
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingVariantsTnumber', value)}
                  value={settings.ABtestingVariantsTnumber}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Choose the number of variant outputs. A lower number is suitable for sites with small traffic, while a higher number caters to websites with at least 30K visitors a month.
                </Text>
              </Col>
            </Row>
  
            {/* Preserve Your Brand Voice and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '150px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Preserve Your Brand Voice (0-10):</Text>
                <Slider
                  min={0}
                  max={10}
                  step={1}
                  onChange={(value) => handleSettingChange('ABtestingBrandVoicePreservation', value)}
                  value={settings.ABtestingBrandVoicePreservation}
                  marks={{
                    0: '0',
                    1: '1',
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                    9: '9',
                    10: '10',
                    
                  }}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Should AI copy your Brand voice characteristics, keeping it closer to your brand style, or have the ability to be more creative and depart from usual brand patterns? A higher value preserves your brand voice more closely.
                </Text>
              </Col>
            </Row>
  
            {/* A/B Testing Settings */}
            {/* Confidence Level for A/B Testing and its description */}
            <Title level={2} align="center" style={{ marginBottom: '50px' }}>A/B Testing Settings</Title>
  
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Confidence Level for A/B Testing:</Text>
                <Slider
                  min={70}
                  max={95}
                  step={5}
                  marks={{
                    70: '70%',
                    75: '75%',
                    80: '80%',
                    85: '85% Recommended',
                    90: '90%',
                    95: '95%',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingConfidenceLevel', value)}
                  value={settings.ABtestingConfidenceLevel}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  This slider adjusts the confidence level required to determine a winning variant in A/B testing. A higher confidence level means requiring more evidence (data) before making a decision, reducing the risk of false positives but potentially requiring more time and traffic.
                </Text>
              </Col>
            </Row>
  
            {/* Early Elimination of Underperforming Variants and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Early Elimination of Underperforming Variants:</Text>
                <Slider
                  min={60}
                  max={70}
                  step={2}
                  marks={{
                    60: '60%',
                    62: '62%',
                    65: '65% Recommended',
                    67: '67%',
                    70: '70%',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingEarlyElimination', value)}
                  value={settings.ABtestingEarlyElimination}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Adjust how quickly underperforming variants are discarded during A/B testing. A lower threshold is less aggressive, keeping variants longer for more data collection, while a higher threshold more quickly eliminates variants deemed underperforming based on early results.
                </Text>
              </Col>
            </Row>
  

            <Row gutter={[16, 24]} style={{ marginBottom: '50px', alignItems: 'top' }}> {/* Increased vertical space for the last slider */}
                    <Col span={12}>
                        <Text>Introduce AI variants slowly to your visitors:</Text>
                        <Slider
                            min={1}
                            max={6}
                            step={1}
                            marks={{
                                1: '100%',
                                2: '80%',
                                3: '60%',
                                4: '40%',
                                5: '10%',
                                6: '0% ',
                            }}
                            defaultValue={1}
                            onChange={(value) => handleSettingChange('ABtestingTrafficPercentageForVariants', value)}
                           tooltipVisible={false}
                        />
                    </Col>
                    <Col span={12} style={{ paddingLeft: '50px' }}>
                        <Text>
                            This percantage of traffic should see AI variants, put it to 10% if you need to introduce AI variants to your visitors very slowly.  Your original text wil be tested in A/B mix in any case as one of variant.
                        </Text>
                    </Col>
                </Row>



  
          </Card>
        </div>
      </div>
    </div>
    </div>
  );
  
};

export default SettingsPage2;