import React, { useState, useEffect } from 'react';
import {
  Select, List, Typography, Input, Button, Divider, Form, Progress, Switch, Flex, Space, Tooltip
} from 'antd';
import { getPageList, getVariantsForUrl, updateVariantOnServer, getLanguagesForUrl, sendDeleteVariantRequest, sendFeatureStatusToServer3, generateVariantsWithAI } from './utils';
import { MenuUnfoldOutlined, SearchOutlined, SaveOutlined, EditOutlined, DeleteOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;
const { Title, Text } = Typography;
const PAGE_SIZE = 300;

function EditableTextArea({ initialValue, onSave }) {
  const [tempValue, setTempValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);

  const handleFocus = () => setIsEditing(true);
  const handleBlur = () => {
    setIsEditing(false);
    onSave(tempValue);
  };

  return (
    <TextArea
      value={tempValue}
      onChange={(e) => setTempValue(e.target.value)}
      onFocus={handleFocus}
      onBlur={handleBlur}
      autoSize={{ minRows: 1, maxRows: 8 }}
      style={{ marginBottom: '8px', width: '100%' }}
    />
  );
}

function VariantMetrics({ conversionRate, confidence }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '12%', alignItems: 'center', marginRight: '10px' }}>
      <div style={{ textAlign: 'center', marginRight: '10px' }}>
      <Tooltip 
  title={`This percentage, determined by MVT (Multivariate Testing), represents the probability that this text variant is performing well. It’s not the conversion rate itself but an estimate of the likelihood that this variant will achieve superior results. A higher percentage indicates better performance: ${Math.min(conversionRate.toFixed(2), 100)}%`}
>
          <Progress
            percent={Number(conversionRate)}
            format={(percent) => `${percent.toFixed(0)}%`}
            strokeColor="#1890ff"
          />
        </Tooltip>
        <Text type="secondary" style={{ display: 'block', fontSize: '10px' }}>
          Conv. Score
        </Text>
      </div>
      <div style={{ textAlign: 'center', marginRight: '10px' }}>
      <Tooltip 
  title={`This AI-estimated confidence score represents how sure the model is about the conversion rate for this variant. The more views this variant receives, the higher the confidence score will be, indicating increased certainty in the model's prediction: ${Math.min(confidence.toFixed(2), 100)}%`}
>
          <Progress
            percent={Number(confidence)}
            format={(percent) => `${percent.toFixed(0)}%`}
            strokeColor="#1890ff"
          />
        </Tooltip>
        <Text type="secondary" style={{ display: 'block', fontSize: '10px' }}>
          Confidence
        </Text>
      </div>
    </div>
  );
}


const cleanUrl = (url) => url ? url.replace(/ Whole website$/, '').trim() : '';


export default function VariantEdit({ initialUrl, initialLanguage }) {
  console.log("Received initialUrl:", initialUrl);

  const cleanedInitialUrl = initialUrl ? initialUrl.replace(/ Whole website$/, '').trim() : '';
  
  console.log("Cleaned initialUrl:", cleanedInitialUrl);

  const [variants, setVariants] = useState([]);

  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage || '');
  const [urls, setUrls] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [aiCreationStates, setAiCreationStates] = useState({});
  const [deletingVariantId, setDeletingVariantId] = useState(null);

  const cleanUrl = (url) => url.replace(/ Whole website$/, '').trim();

  const [selectedUrl, setSelectedUrl] = useState(cleanUrl(initialUrl || ''));



  useEffect(() => {
    console.log("selectedUrl in useEffect:", selectedUrl);
    const fetchPageList = async () => {
      if (!initialUrl) {
        const pageList = await getPageList();
        if (pageList) {
          setUrls(pageList);
        }
      }
    }
    fetchPageList();
  }, [initialUrl]);

  useEffect(() => {
    if (initialUrl) {
      setSelectedUrl(initialUrl);
      fetchLanguages(initialUrl);
    }
  }, [initialUrl]);

  useEffect(() => {
    if (initialLanguage) {
      setSelectedLanguage(initialLanguage);
    }
  }, [initialLanguage]);

  useEffect(() => {
    console.log("Fetching variants for URL:", selectedUrl, "and language:", selectedLanguage || initialLanguage);
    const fetchVariants = async () => {
      if (selectedUrl && (selectedLanguage || initialLanguage)) {
        const cleanedUrl = cleanUrl(selectedUrl);
        console.log("Fetching variants for cleaned URL:", cleanedUrl);
        const variantsData = await getVariantsForUrl(cleanedUrl, selectedLanguage || initialLanguage);
  
        if (!variantsData) {
          console.error("No data returned from getVariantsForUrl");
          return;
        }
  
        const validVariantsData = variantsData.filter(variantData => Array.isArray(variantData[2]));
  
        const variantsWithEditFlagsAndKeys = validVariantsData.map((variantData, index) => {
          const [originalText, aiSwitch, variantsArray, language] = variantData;
  
          const updatedVariants = variantsArray.map(variant => {
            const views = variant.Views || 0;
            let displayedConversionRate = parseFloat(variant.ConversionRate) * 100;

            // Apply penalty only if conversion rate is 100%
            if (displayedConversionRate === 100) {
              const maxPenalty = 50;
              const penaltyFactor = Math.max(0, 1 - views / 1000);
              const penalty = maxPenalty * penaltyFactor;
              displayedConversionRate = Math.max(50, 100 - penalty);
            }
            let confidence;
            if (views <= 200) {
              // Fast growth up to 60% for first 200 views
              confidence = (views / 200) * 60;
            } else if (views <= 400) {
              // Slower growth from 60% to 80% between 201 and 400 views
              confidence = 60 + ((views - 200) / 200) * 20;
            } else if (views < 2000) {
              // Very slow growth from 80% to 100% between 401 and 1999 views
              confidence = 80 + ((views - 400) / 1600) * 20;
            } else {
              // 100% confidence at 2000 views and above
              confidence = 100;
            }
            
      
            
            // Ensure it doesn't exceed 100%
            confidence = Math.min(confidence, 100);
  
            return {
              ...variant,
              renderKey: `variant-${index}-${variant.ID}`,
              displayedConversionRate: displayedConversionRate,
              confidence: confidence
            };
          });
  
          return [originalText, aiSwitch, updatedVariants, language];
        });
  
        setVariants(variantsWithEditFlagsAndKeys);
        setAiCreationStates(variantsWithEditFlagsAndKeys.reduce((states, variantData) => {
          states[variantData[0]] = variantData[1]; // Set AI switch status
          return states;
        }, {}));
        setPageIndex(0);
      }
    };
    fetchVariants();
  }, [selectedUrl, selectedLanguage, initialLanguage]);


  
  const fetchLanguages = async (url) => {
    if (url) {
      const languagesData = await getLanguagesForUrl(url);
      if (languagesData && Array.isArray(languagesData)) {
        setLanguages(languagesData);
      }
    }
  };

  useEffect(() => {
    if (selectedUrl) {
      fetchLanguages(selectedUrl);
    }
  }, [selectedUrl]);

  const handleUrlChange = (value) => {
    setSelectedUrl(cleanUrl(value));
  };

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value);
  };

  const handleVariantSave = (originalIndex, variantIndex, newText) => {
    setVariants(prevVariants => {
      const newVariants = JSON.parse(JSON.stringify(prevVariants));
      newVariants[originalIndex][2][variantIndex].tempText = newText;
      newVariants[originalIndex][2][variantIndex].edited = true;
      return newVariants;
    });
  };

  const handleAddNewVariant = (originalIndex) => {
    setVariants(prevVariants => {
      const newVariants = [...prevVariants];
      newVariants[originalIndex][2].push({ renderKey: `new-${Date.now()}`, ID: 999, Text: '', tempText: '' });
      return newVariants;
    });
  };

  const handleDeleteVariant = (pageOriginalIndex, pageVariantIndex) => {
    const globalOriginalIndex = pageIndex * PAGE_SIZE + pageOriginalIndex;
    const variantToDelete = variants[globalOriginalIndex]?.[2]?.[pageVariantIndex];

    if (!variantToDelete) {
      console.error("Invalid variant selection for deletion");
      return;
    }

    if (variantToDelete.ID === 999) {
      removeVariantFromState(globalOriginalIndex, pageVariantIndex);
    } else {
      setDeletingVariantId(`${globalOriginalIndex}-${pageVariantIndex}`);
      const deleteRequestData = {
        Url: cleanUrl(selectedUrl),
        Language: selectedLanguage,
        OriginalText: variants[globalOriginalIndex][0],
        VariantText: variantToDelete.Text,
      };

      sendDeleteVariantRequest(deleteRequestData).then(deleteResponse => {
        if (deleteResponse.success) {
          removeVariantFromState(globalOriginalIndex, pageVariantIndex);
          console.log("Variant deleted successfully");
        } else {
          console.error("Server error:", deleteResponse.error);
        }
        setDeletingVariantId(null);
      }).catch(error => {
        console.error("An error occurred:", error);
        setDeletingVariantId(null);
      });
    }
  };

  const removeVariantFromState = (globalOriginalIndex, variantIndex) => {
    setVariants(prevVariants => {
      const newVariants = [...prevVariants];
      if (newVariants[globalOriginalIndex] && newVariants[globalOriginalIndex][2]) {
        newVariants[globalOriginalIndex][2].splice(variantIndex, 1);
        if (newVariants[globalOriginalIndex][2].length === 0) {
          newVariants.splice(globalOriginalIndex, 1);
        }
      }
      return newVariants;
    });
  };

  const handleAiCreationChange = async (checked, originalIndex) => {
    const originalText = variants[originalIndex][0];
    const cleanedUrl = cleanUrl(selectedUrl);
    const language = selectedLanguage;
  
    setVariants(prevVariants => {
      const newVariants = [...prevVariants];
      newVariants[originalIndex][1] = checked;
      return newVariants;
    });
  
    setAiCreationStates(prevStates => ({
      ...prevStates,
      [originalText]: checked
    }));
  
    try {
      await sendFeatureStatusToServer3({
        cleanedUrl,
        language,
        originalText,
        status: checked
      });
      console.log('AI creation state sent to server');
    } catch (error) {
      console.error('Error sending AI creation state to server:', error);
    }
  };

  const handleSaveEdits = async () => {
    const cleanedUrl = cleanUrl(selectedUrl);
    const dataToSend = variants
      .map((variantData, originalIndex) => {
        const [originalText, aiSwitch, variants, language] = variantData;
        const processedVariants = variants
          .filter(variant => variant.tempText !== undefined)
          .map(variant => ({
            ID: variant.ID,
            Text: variant.tempText ? variant.tempText : variant.Text
          }));

        if (processedVariants.length > 0) {
          return {
            Url: cleanedUrl,
            Language: language,
            Text: originalText,
            Variants: processedVariants,
            AiCreation: aiSwitch
          };
        }
        return null;
      })
      .filter(Boolean);

    if (dataToSend.length > 0) {
      try {
        await updateVariantOnServer(dataToSend);
        console.log("Variants updated successfully!");
      } catch (error) {
        console.error("Error updating variants:", error);
      }
    } else {
      console.log("No changes to send to the server.");
    }
  };

  const handleGenerateAIVariants = async (originalIndex) => {
    const originalText = variants[originalIndex][0];
    const cleanedUrl = cleanUrl(selectedUrl);
    try {
      const { success, data } = await generateVariantsWithAI(cleanedUrl, selectedLanguage, originalText);
      if (success) {
        setVariants(prevVariants => {
          const newVariants = JSON.parse(JSON.stringify(prevVariants));
          const existingVariantIds = new Set(newVariants[originalIndex][2].map(v => v.ID));
          
          const filteredNewVariants = data.filter(variant => !existingVariantIds.has(variant.ID));
          
          newVariants[originalIndex][2] = [
            ...newVariants[originalIndex][2],
            ...filteredNewVariants.map(variant => ({
              ...variant,
              renderKey: `variant-${variant.ID}`,
              displayedConversionRate: parseFloat(variant.ConversionRate) * 100,
              confidence: Math.min(variant.Views / 100 * 100, 100)
            }))
          ];
          return newVariants;
        });
        console.log("AI variants added successfully");
      } else {
        console.error("Failed to generate AI variants:", data.error);
      }
    } catch (error) {
      console.error("Error generating AI variants:", error);
    }
  };

  const handleNextPage = () => {
    setPageIndex(pageIndex + 1);
  };

  const handlePreviousPage = () => {
    setPageIndex(pageIndex - 1);
  };

  const filteredVariants = variants.filter(variant => variant[3] === selectedLanguage);
  const pageVariants = filteredVariants.slice(pageIndex * PAGE_SIZE, (pageIndex + 1) * PAGE_SIZE);

  return (
    <div data-seatextdont>
      <div style={{ padding: '20px' }}>
        <Form layout="vertical">
          {!initialUrl && (
            <Form.Item label="Please choose page you want to edit:">
              <Select value={selectedUrl} onChange={handleUrlChange}>
                {urls.map((url, index) => (
                  <Option value={url} key={index}>{url}</Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {selectedUrl && !initialLanguage && (
            <Form.Item label="Please choose language:">
              <Select value={selectedLanguage} onChange={handleLanguageChange}>
                {languages.map((language, index) => (
                  <Option value={language} key={index}>{language}</Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <List>
            {pageVariants.map((variantData, originalIndex) => {
              const [originalText, aiSwitch, variants, language] = variantData;
              return (
                <List.Item key={originalIndex} style={{ width: '100%' }}>
                  <div style={{ backgroundColor: 'white', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', padding: '20px', marginBottom: '5px', width: '100%' }}>
                    <Title level={4} style={{ borderBottom: '2px solid #E8E8E8', paddingBottom: '10px', marginBottom: '20px' }}>{originalText}</Title>
                    {variants.map((variant, variantIndex) => (
  <div key={variant.renderKey} style={{ marginBottom: '16px', width: '100%' }}>
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <VariantMetrics
        conversionRate={parseFloat(variant.displayedConversionRate) || 0}
        confidence={parseFloat(variant.confidence) || 0}
      />
      <EditableTextArea
        initialValue={variant.tempText || variant.Text}
        onSave={(newText) => handleVariantSave(originalIndex, variantIndex, newText)}
      />
      {variants.length > 1 && (
        <Button
          icon={<DeleteOutlined />}
          style={{ background: 'transparent', borderColor: 'transparent', fontWeight: 'normal', fontSize: '12px' }}
          loading={deletingVariantId === `${originalIndex}-${variantIndex}`}
          onClick={() => handleDeleteVariant(originalIndex, variantIndex)}
        >
          Delete Variant
        </Button>
      )}
    </div>
  </div>
                    ))}

                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <Button
                        icon={<SaveOutlined />}
                        style={{ background: 'transparent', borderColor: 'transparent', fontWeight: 'normal', fontSize: '14px' }}
                        onClick={handleSaveEdits}
                      >
                        Save Edits
                      </Button>
                      <Button
                        icon={<EditOutlined />}
                        style={{ background: 'transparent', borderColor: 'transparent', fontWeight: 'normal', fontSize: '14px' }}
                        onClick={() => handleAddNewVariant(originalIndex)}
                      >
                        Add New Variant
                      </Button>
                      <Button
                        icon={<EditOutlined />}
                        style={{ background: 'transparent', borderColor: 'transparent', fontWeight: 'normal', fontSize: '14px' }}
                        onClick={() => handleGenerateAIVariants(originalIndex)}
                      >
                        Generate AI Variants
                      </Button>

                      <div style={{ marginLeft: '10px' }}>
                        <Switch
                          checked={aiSwitch}
                          onChange={(checked) => handleAiCreationChange(checked, originalIndex)}
                          checkedChildren="AI ON"
                          unCheckedChildren="AI OFF"
                        />
                        <span style={{ marginLeft: '10px' }}>Allow AI to create more new variants  if all variants are tested</span>
                      </div>
                    </div>
                  </div>
                </List.Item>
              );
            })}

            {/* Pagination Controls */}
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '20px' }}>
              <Button
                onClick={handlePreviousPage}
                disabled={pageIndex === 0}
                icon={<LeftOutlined />}
                style={{ marginRight: '30px' }}
              >
                Previous Page
              </Button>
              <Button
                onClick={handleNextPage}
                disabled={pageIndex * PAGE_SIZE + PAGE_SIZE >= filteredVariants.length}
                icon={<RightOutlined />}
              >
                Next Page
              </Button>
            </div>
          </List>
        </Form>
      </div>
    </div>
  );
}