import React, { useState, useEffect } from 'react';
import { Typography, Card, Row, Col, Slider, Switch, Transfer, message, Alert } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { sendFeatureStatusToServer2,fetchSettingsFromServer } from './utils';
import "./style.css";



const { Title, Text } = Typography;
 

// Initialize an array of 50+ languages
const allLanguages = [
    "English", "Spanish", "French", "German", "Chinese", "Russian", "Japanese", "Korean", "Italian", "Dutch",
    "Portuguese", "Arabic", "Swedish", "Danish", "Finnish", "Norwegian", "Polish", "Turkish", "Czech", "Slovak",
    "Hungarian", "Romanian", "Bulgarian", "Greek", "Lithuanian", "Latvian", "Estonian", "Croatian", "Serbian",
    "Slovenian", "Macedonian", "Albanian", "Ukrainian", "Belarusian", "Armenian", "Azerbaijani", "Georgian",
    "Kazakh", "Uzbek", "Turkmen", "Tajik", "Kyrgyz", "Mongolian", "Persian", "Hebrew", "Hindi", "Bengali", "Thai",
    "Vietnamese", "Malay", "Indonesian", "Filipino", "Swahili", "Nepali", "Urdu", "Sinhala", "Khmer", "Lao",
    "Burmese", "Kurdish", "Amharic", "Zulu", "Somali", "Hausa", "Yoruba", "Igbo", "Cebuano", "Malagasy",
    "Sundanese", "Javanese", "Maori", "Pashto", "Tamil", "Telugu", "Kannada", "Malayalam", "Gujarati", "Marathi",
    "Oriya", "Punjabi", "Assamese", "Bosnian", "Sicilian", "Luxembourgish", "Corsican", "Samoan", "Tongan",
    "Fijian", "Greenlandic", "Inuktitut", "Aymara", "Quechua", "Guarani", "Tigrinya", "Maltese", "Kazakh Latin",
    "Sorbian", "Welsh", "Breton", "Irish", "Scottish Gaelic", "Manx", "Cornish", "Basque", "Catalan", "Galician",
    "Faroese", "Icelandic"
  ].map(lang => ({ key: lang, title: lang }));





  function Etrade(props) {
    // Define default settings
    const defaultSettings = {
      ProductDescOptEnabled:true,
      
      ProductNameCustomizationEnabled: true,
      BrandMessagingOptimizationEnabled: true,
      CustomerReviewsEnhancementEnabled: true,
      EnhancedTechnicalDetailsEnabled: true,
      IngredientQualityDescriptionsEnabled: true,
      PackagingTextRefinementEnabled: true,
      DynamicProductDescriptionsEnabled: true,
      InteractiveButtonTextsEnabled: true,
      SmallTaglinesEnabled: true,
      ABtestingCreativityLevel: 3,
      ABtestingVariantsTnumber: 5,
      ABtestingConfidenceLevel: 85,
      ABtestingEarlyElimination: 65,
      ABtestingBrandVoicePreservation: 5,
      ABtestingTrafficPercentageForVariants: 1,
    };
  
    const [settings, setSettings] = useState(defaultSettings);
    const [showAiABTestSwitch, setShowAiABTestSwitch] = useState(false);
    const [modifiedUrl, setModifiedUrl] = useState('');
  
    useEffect(() => {
      let initialUrl = props.context?.url || '';
      let isUrlFromCookies = false;
  
      if (!initialUrl) {
        const domainName = Cookies.get('domainName');
        if (domainName) {
          initialUrl = `${domainName} Whole website`;
          setShowAiABTestSwitch(true);
          isUrlFromCookies = true;
        }
      } else {
        setShowAiABTestSwitch(false);
      }
  
      setModifiedUrl(initialUrl); // Update the state with the modified URL
  
      async function loadSettings() {
        try {
          const serverSettings = await fetchSettingsFromServer(initialUrl);
          if (serverSettings) {
            setSettings({ ...defaultSettings, ...serverSettings });
          }
        } catch (error) {
          console.error('Failed to fetch settings:', error);
        }
      }
  
      loadSettings();
  
      return () => {
        if (isUrlFromCookies) {
          // Perform cleanup or additional actions when the component unmounts or URL changes
        }
      };
    }, [props.context?.url]); // Depend on `props.context.url` to re-run this effect when it changes
  
    const handleSettingChange = (key, value) => {
      const updatedSettings = { ...settings, [key]: value };
      setSettings(updatedSettings);
  
      const payload = {
        url: modifiedUrl, // Use the modified URL for server requests
        data: updatedSettings,
      };
  
      sendFeatureStatusToServer2(payload).catch(error => {
        console.error(error);
        message.error('Failed to update settings.');
      });
    };
  
    const renderTitle = () => {
      const titleBase = "AI for online stores. You are editing settings ";
      return modifiedUrl ? `${titleBase} for ${modifiedUrl}` : titleBase;
    };
  



  return (
    <div style={{ width: '1400px', maxWidth: '1900px', minWidth: '1200px' }}>
    <Title 
      level={1} 
      align="left" 
      style={{ 
        fontSize: '24px', 
        fontWeight: 'bold',
        marginTop: '20px',  // Adds vertical spacing
        marginLeft: '30px'  // Adds horizontal spacing to the left
      }}
    >
      {renderTitle()}
    </Title>



    <Alert
  message="Important Information"
  description={
    <>
      This page allows you to control the scope of AI—determining what text AI can and cannot fine-tune. While there are several methods to limit AI scope, we recommend using the 'sea`textdont' and 'sea`textonly' commands as the most effective approach. You can find detailed information about these commands in our documentation at <a href="https://seatext.com/documentation-ai-scope" target="_blank" rel="noopener noreferrer">https://seatext.com/documentation-ai-scope</a>. We strongly advise using these methods first. The sliders below should only be used if you cannot edit your website directly or if you don't have time to manually create these tags.
    </>
  }
  type="info"
  showIcon
  icon={<InfoCircleOutlined />}
  style={{ marginBottom: '20px' }}
/>


      <div style={{ padding: '20px', display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '100%' }}>
        <div className="frame">
      <div className="online-sotre-ais">
        <div className=".div-2" />
        <div className="div" />
        <div className="div-2" />
        <div className="div" />
        <div className="div-2" />
        <div className="div" />
        <div className="div-2" />
        <div className="div" />
        <div className="div-2" />
        <Switch
            checked={settings.ProductNameCustomizationEnabled}
            onChange={(checked) => handleSettingChange('ProductNameCustomizationEnabled', checked)}
            className="switch-instance"
            disabled={false}
            icon={false}
            loading={false}
            size="medium"
            text
          />
          <div className="text-wrapper">Product Name Customization</div>
          <p className="enable-AI-to-enhance">Enable AI to enhance product names by appending descriptors such as "innovative," "refreshing," or "robust." This feature leverages AI's contextual understanding to aptly complement your product names. However, be aware that some suggestions may be suboptimal and require manual review.</p>
          <Switch
            checked={settings.BrandMessagingOptimizationEnabled}
            onChange={(checked) => handleSettingChange('BrandMessagingOptimizationEnabled', checked)}
            className="design-component-instance-node"
            disabled={false}
            icon={false}
            loading={false}
            size="medium"
            text
          />
          <div className="text-wrapper-2">Brand Messaging Optimization</div>
          <p className="AI-can-subtly-refine">AI can subtly refine your brand messages and slogans without overhauling the core message. As the most impactful yet sensitive tool, we recommend using it with caution. Monitor the variant editor to review the AI's outputs and their effectiveness.</p>
          <Switch
            checked={settings.CustomerReviewsEnhancementEnabled}
            onChange={(checked) => handleSettingChange('CustomerReviewsEnhancementEnabled', checked)}
            className="switch-2"
            disabled={false}
            icon={false}
            loading={false}
            size="medium"
            text
          />
          <div className="text-wrapper-3">Customer Reviews Enhancement</div>
          <p className="p">Our AI can refine customer reviews by improving language, correcting grammatical errors, and moderating tone. It enhances readability and professionalism without altering the original sentiment. Note: If you wish to remove negative reviews, this must be done manually.</p>
          <Switch
            checked={settings.EnhancedTechnicalDetailsEnabled}
            onChange={(checked) => handleSettingChange('EnhancedTechnicalDetailsEnabled', checked)}
            className="switch-3"
            disabled={false}
            icon={false}
            loading={false}
            size="medium"
            text
          />
          <div className="text-wrapper-4">Enhanced Technical Details</div>
          <p className="improve-your-product">Improve your product's technical specifications with AI. It can optimize language and adapt measurements to relevant units (e.g., converting to imperial units for the US market). Rest assured, numerical data remains unchanged as we prioritize accuracy.</p>
          <Switch
            checked={settings.IngredientQualityDescriptionsEnabled}
            onChange={(checked) => handleSettingChange('IngredientQualityDescriptionsEnabled', checked)}
            className="switch-4"
            disabled={false}
            icon={false}
            loading={false}
            size="medium"
            text
          />
          <div className="text-wrapper-5">Ingredient and Quality Descriptions</div>
          <p className="text-wrapper-6">AI can fine-tune descriptions related to ingredients and manufacturing quality, aligning closely with your original style to preserve your brand’s identity.</p>
          <Switch
            checked={settings.PackagingTextRefinementEnabled}
            onChange={(checked) => handleSettingChange('PackagingTextRefinementEnabled', checked)}
            className="switch-5"
            disabled={false}
            icon={false}
            loading={false}
            size="medium"
            text />
        <div className="text-wrapper-7">Packaging Text Refinement</div>
        <p className="AI-can-fine-tune-the">
          AI can fine-tune the text on your packaging, which is crucial for international shipments or when adding
          special items. For luxury packaging, precise text adjustments can significantly enhance your brand&#39;s
          presentation.
        </p>
        <Switch
            checked={settings.DynamicProductDescriptionsEnabled}
              onChange={(checked) => handleSettingChange('DynamicProductDescriptionsEnabled', checked)}
            className="switch-7"
            disabled={false}
            icon={false}
            loading={false}
            size="medium"
            text
          />
        <div className="text-wrapper-8">Dynamic Product Descriptions</div>
        <p className="text-wrapper-9">
          Our AI can generate variations of your product descriptions, emphasizing different benefits. This allows
          you to discover what resonates most with your customers and refine your messaging accordingly. You can
          maintain your brand’s voice or increase creativity via an adjustable slider, providing more flexibility in
          expression
        </p>
        <Switch
            checked={settings.InteractiveButtonTextsEnabled}
            onChange={(checked) => handleSettingChange('InteractiveButtonTextsEnabled', checked)}
            className="switch-8"
            disabled={false}
            icon={false}
            loading={false}
            size="medium"
            text
          />
          <div className="text-wrapper-10">Interactive Button Texts</div>
          <p className="AI-can-analyze-and">AI can analyze and test various texts for interactive buttons like "Buy Now." It generates thousands of alternatives, presenting a unique version to each user per session. This ensures a dynamic and engaging user experience without creating inconsistencies across different pages.</p>
          <Switch
            checked={settings.SmallTaglinesEnabled}
            onChange={(checked) => handleSettingChange('SmallTaglinesEnabled', checked)}
            className="switch-9"
            disabled={false}
            icon={false}
            loading={false}
            size="medium"
            text
          />
        <div className="text-wrapper-11">Small taglines</div>
        <p className="text-wrapper-12">
          Small taglines play a crucial role in capturing customer interest. Our AI can generate several variations of
          your existing tagline and create new ones inspired by the content of your page. This enables you to initiate
          A/B testing with multiple options, helping you determine the most effective tagline to engage your audience.
        </p>
      </div>
      <img className="product-name" alt="Product name" src="https://login.seatext.com/product-name-customization-img-1.png" />
      <img className="small-taglines-img" alt="Small taglines img" src="https://login.seatext.com/small-taglines-img-1.png" />
      <img className="interactive-button" alt="Interactive button" src="https://login.seatext.com/interactive-button-texts-img-1.png" />
      <img className="dynamic-product" alt="Dynamic product" src="https://login.seatext.com/dynamic-product-descriptions-img-1.png" />
      <img className="packaging-text" alt="Packaging text" src="https://login.seatext.com/packaging-text-refinement-img-1.png" />
      <img className="enhanced-technical" alt="Enhanced technical" src="https://login.seatext.com/enhanced-technical-details-img-1.png" />
      <img className="ingredient-and" alt="Ingredient and" src="https://login.seatext.com/ingredient-and-quality-descriptions-img-1.png" />
      <img className="customer-reviews" alt="Customer reviews" src="https://login.seatext.com/customer-reviews-enhancement-img-1.png" />
      <img className="brand-messaging" alt="Brand messaging" src="https://login.seatext.com/brand-messaging-optimization-img-1.png" />
    </div>
  





          
          <Card style={{ padding: '20px 50px' }}>
            
  

  
<Text align="left" style={{ fontSize: '24px', fontWeight: 'bold' }}>
  General AI Settings
</Text>

            {/* AI Creativity Level and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>AI Creativity Level:</Text>
                <Slider
                  min={1}
                  max={5}
                  step={1}
                  marks={{
                    1: 'Max Creativity',
                    2: 'High',
                    3: 'Medium',
                    4: 'Low',
                    5: 'Minimal Changes',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingCreativityLevel', value)}
                  value={settings.ABtestingCreativityLevel}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Adjust how creative the AI should be. A lower setting means the AI will generate content that's significantly different from the original, while a higher setting limits creativity to minimal changes.
                </Text>
              </Col>
            </Row>
  
            {/* Number of Variants to Create and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Number of Variants to Create:</Text>
                <Slider
                  min={2}
                  max={8}
                  step={1}
                  marks={{
                   
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingVariantsTnumber', value)}
                  value={settings.ABtestingVariantsTnumber}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Choose the number of variant outputs. A lower number is suitable for sites with small traffic, while a higher number caters to websites with at least 30K visitors a month.
                </Text>
              </Col>
            </Row>
  
            {/* Preserve Your Brand Voice and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '150px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Preserve Your Brand Voice (0-10):</Text>
                <Slider
                  min={0}
                  max={10}
                  step={1}
                  onChange={(value) => handleSettingChange('ABtestingBrandVoicePreservation', value)}
                  value={settings.ABtestingBrandVoicePreservation}
                  marks={{
                    0: '0',
                    1: '1',
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                    9: '9',
                    10: '10',
                    
                  }}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Should AI copy your Brand voice characteristics, keeping it closer to your brand style, or have the ability to be more creative and depart from usual brand patterns? A higher value preserves your brand voice more closely.
                </Text>
              </Col>
            </Row>
  
            {/* A/B Testing Settings */}
            {/* Confidence Level for A/B Testing and its description */}
    
<Text align="left" style={{ fontSize: '24px', fontWeight: 'bold' }}>
A/B Testing Settings
</Text>

            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Confidence Level for A/B Testing:</Text>
                <Slider
                  min={70}
                  max={95}
                  step={5}
                  marks={{
                    70: '70%',
                    75: '75%',
                    80: '80%',
                    85: '85% Recommended',
                    90: '90%',
                    95: '95%',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingConfidenceLevel', value)}
                  value={settings.ABtestingConfidenceLevel}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  This slider adjusts the confidence level required to determine a winning variant in A/B testing. A higher confidence level means requiring more evidence (data) before making a decision, reducing the risk of false positives but potentially requiring more time and traffic.
                </Text>
              </Col>
            </Row>
  
            {/* Early Elimination of Underperforming Variants and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Early Elimination of Underperforming Variants:</Text>
                <Slider
                  min={60}
                  max={70}
                  step={1}
                  marks={{
                    60: '60%',
                    62: '62%',
                    65: '65% Recommended',
                    67: '67%',
                    70: '70%',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingEarlyElimination', value)}
                  value={settings.ABtestingEarlyElimination}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Adjust how quickly underperforming variants are discarded during A/B testing. A lower threshold is less aggressive, keeping variants longer for more data collection, while a higher threshold more quickly eliminates variants deemed underperforming based on early results.
                </Text>
              </Col>
            </Row>
  

            <Row gutter={[16, 24]} style={{ marginBottom: '50px', alignItems: 'top' }}> {/* Increased vertical space for the last slider */}
                    <Col span={12}>
                        <Text>Introduce AI variants slowly to your visitors:</Text>
                        <Slider
                            min={1}
                            max={6}
                            step={1}
                            marks={{
                                1: '100%',
                                2: '80%',
                                3: '60%',
                                4: '40%',
                                5: '10%',
                                6: '0% ',
                            }}
                            defaultValue={1}
                            onChange={(value) => handleSettingChange('ABtestingTrafficPercentageForVariants', value)}
                           tooltipVisible={false}
                        />
                    </Col>
                    <Col span={12} style={{ paddingLeft: '50px' }}>
                        <Text>
                            This percantage of traffic should see AI variants, put it to 10% if you need to introduce AI variants to your visitors very slowly.  Your original text wil be tested in A/B mix in any case as one of variant.
                        </Text>
                    </Col>
                </Row>



  
          </Card>
        </div>
      </div>
    </div>
  );
  
};

export default Etrade;